(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

module.exports = {
    getCurrentLocation: function getCurrentLocation(callback) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
                callback(currentLocation);
            });
        }
    },

    getDirections: function getDirections(origin, destination, callback) {
        var url = 'https://www.google.com/maps/dir/{0}/{1}/'.format(origin.lat + ',' + origin.lng, destination.lat + ',' + destination.lng);
        window.open(url, 'google_directions', 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes');
        if (callback) callback(origin);
    },

    geocode: function geocode(address, callback) {
        if (geocoder) {
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == 'OK' && results[0]) {
                    var location = results[0].geometry.location;
                    var resolvedCoords = { lat: location.lat(), lng: location.lng() };
                    callback(resolvedCoords);
                }
            });
        }
    },

    reverseGeocode: function reverseGeocode(latLng, callback) {
        if (geocoder) {
            geocoder.geocode({ 'location': latLng }, function (results, status) {
                if (status == 'OK' && results[0]) {
                    var resolvedLocation = results[0];
                    callback(resolvedLocation.formatted_address);
                }
            });
        }
    },

    getNearestOffice: function getNearestOffice(currentLocation, offices, callback) {
        var _self = this;
        var mindif = 99999,
            closest;

        if (currentLocation) {
            for (var i = 0; i < offices.length; i++) {
                var dif = _self.PythagorasEquirectangular(currentLocation.lat, currentLocation.lng, offices[i].latLng.lat, offices[i].latLng.lng);
                if (dif < mindif) {
                    closest = i;
                    mindif = dif;
                }
            }

            if (closest || closest === 0) {
                var closestOffice = offices[closest];
                callback(closestOffice);
            }
        }
    },

    PythagorasEquirectangular: function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
        var _self = this;

        lat1 = _self.Deg2Rad(lat1);
        lat2 = _self.Deg2Rad(lat2);
        lon1 = _self.Deg2Rad(lon1);
        lon2 = _self.Deg2Rad(lon2);
        var R = 6371; // km
        var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
        var y = lat2 - lat1;
        var d = Math.sqrt(x * x + y * y) * R;
        return d;
    },

    Deg2Rad: function Deg2Rad(deg) {
        return deg * Math.PI / 180;
    }
};

},{}],2:[function(require,module,exports){
"use strict";

Vue.use(VueResource);

var relatedExperience, relatedArticles, contactInfo, officeListing, siteSearchResults, contactUs, goldTabs, featuredPersonCarousel, screenRead, globalSolutions;

require("./custom/sections/articles");
require("./custom/sections/capabilities");
require("./custom/sections/experience");
require("./custom/sections/people");
require("./custom/sections/careers-gen-content");
require("./custom/features/locations");

if (document.getElementById("related-experience-app")) {
    relatedExperience = new Vue(require("./custom/features/related-experience"));
}

if (document.getElementById("related-articles-app")) {
    relatedArticles = new Vue(require("./custom/features/related-articles"));
}

if (document.getElementById("contact-info-app")) {
    contactInfo = new Vue(require("./custom/features/contact-info"));
}

if (document.getElementById("office-listing-app")) {
    officeListing = new Vue(require("./custom/features/office-listing"));
}
if (document.getElementById("clock")) {
    screenRead = new Vue(require("./custom/features/clock"));
}

if (document.getElementById("site-search-results")) {
    siteSearchResults = new Vue(require("./custom/features/sitesearch-results"));
}

if (document.getElementById("contact-us-app")) {
    contactUs = new Vue(require("./custom/features/contact-us"));
}

if (document.getElementById("gold-tabs-app")) {
    goldTabs = new Vue(require("./custom/features/gold-tabs"));
}

if (document.getElementById("featured-people-app")) {
    featuredPersonCarousel = new Vue(require("./custom/features/featured-person-carousel"));
}

if (document.getElementById("diversity-carousel-app")) {
    featuredPersonCarousel = new Vue(require("./custom/features/diversity-carousel"));
}

},{"./custom/features/clock":3,"./custom/features/contact-info":4,"./custom/features/contact-us":5,"./custom/features/diversity-carousel":6,"./custom/features/featured-person-carousel":7,"./custom/features/gold-tabs":8,"./custom/features/locations":9,"./custom/features/office-listing":10,"./custom/features/related-articles":11,"./custom/features/related-experience":12,"./custom/features/sitesearch-results":14,"./custom/sections/articles":15,"./custom/sections/capabilities":16,"./custom/sections/careers-gen-content":17,"./custom/sections/experience":18,"./custom/sections/people":19}],3:[function(require,module,exports){
'use strict';

var ScreenRead = require('./screen-read');

module.exports = {
  el: '#clock',
  components: {
    'ScreenRead': ScreenRead
  }
};

},{"./screen-read":13}],4:[function(require,module,exports){
"use strict";

module.exports = {
    el: '#contact-info-app',

    data: {
        showModal: false,
        form: {},
        formLoaded: false,
        professionalId: "",
        name: "",
        email: "",
        message: "",
        formRecaptcha: null,
        showVCardModal: false,
        vCardForm: {},
        vCardFormLoaded: false,
        vCardRecaptcha: null,
        recaptchaPublicKey: "",
        honeypot: ""
    },

    created: function created() {
        this.$http.get('/api/shared/emailform').then(function (response) {
            var self = this;
            this.form = response.body.form;
            this.formLoaded = true;
            // setTimeout(function() {
            //     self.loadRecaptcha('form-captcha')
            // }, 500);
        }, function (error) {
            console.log(error);
        });
        this.$http.get('/api/shared/vcard').then(function (response) {
            var self = this;
            this.vCardForm = response.body.vcard;
            this.vCardFormLoaded = true;
            // setTimeout(function() {
            //     self.loadRecaptcha('v-card-captcha')
            // }, 500);
        }, function (error) {
            console.log(error);
        });

        this.recaptchaPublicKey = document.getElementById('contact-us-form').getAttribute('data-recaptcha-key');
    },

    methods: {
        accept: function accept(emailAddress) {
            var mailTo = 'mailto:' + emailAddress;
            window.location.href = mailTo;
            this.toggleModal();
        },

        decline: function decline() {
            this.toggleModal();
        },
        clearEmailForm: function clearEmailForm() {
            this.name = "";
            this.email = "";
            this.message = "";
            this.form.NameError = "";
            this.form.EmailError = "";
            this.form.MessageError = "";
            this.form.CaptchaError = "";
        },
        clearVCardForm: function clearVCardForm() {
            this.vCardForm.DisplayMessage = "";
        },
        loadRecaptcha: function loadRecaptcha(id) {
            var self = this;
            setTimeout(function () {
                if (typeof grecaptcha === 'undefined') {
                    self.loadRecaptcha(id);
                } else {
                    if (id == "v-card-captcha") {
                        self.vCardRecaptcha = grecaptcha.render(id, {
                            sitekey: '6Le6sx4UAAAAAEvHiBxQCMkeaKEoUdlu8nFn9c_q',
                            callback: self.recaptchaCallback
                        });
                    } else {
                        self.formRecaptcha = grecaptcha.render(id, {
                            sitekey: '6Le6sx4UAAAAAEvHiBxQCMkeaKEoUdlu8nFn9c_q',
                            callback: self.recaptchaCallback
                        });
                    }
                }
            }, 100);
        },

        recaptchaCallback: function recaptchaCallback() {
            console.log("callback");
        },

        hideForm: function hideForm() {
            var form = document.getElementsByClassName('email-form__wrapper')[0];
            if (!form.classList.contains('hide')) {
                form.classList.add('hide');
            }
        },

        unhideForm: function unhideForm() {
            var form = document.getElementsByClassName('email-form__wrapper')[0];
            if (form.classList.contains('hide')) {
                form.classList.remove('hide');
            }
        },

        toggleModal: function toggleModal(professionalId) {
            if (professionalId) {
                this.professionalId = professionalId;
            }
            var body = document.getElementsByTagName('body')[0];
            body.classList.toggle('modal-lock');
            this.showModal = !this.showModal;

            if (!this.showModal) {
                this.clearEmailForm();
                this.unhideForm();
            } else {
                this.hideMessage();
            }
        },

        toggleVCardModal: function toggleVCardModal(professionalId) {
            if (professionalId) {
                this.professionalId = professionalId;
            }
            var body = document.getElementsByTagName('body')[0];
            body.classList.toggle('modal-lock');
            this.showVCardModal = !this.showVCardModal;

            if (!this.showModal) {
                this.clearVCardForm();
            }
        },
        print: function print() {
            window.print();
        },
        showMessage: function showMessage(message) {
            this.hideForm();
            this.form.DisplayMessage = message;
            this.$forceUpdate();
        },
        showVCardMessage: function showVCardMessage(message) {
            this.vCardForm.DisplayMessage = message;
            this.$forceUpdate();
        },
        hideMessage: function hideMessage(message) {
            this.form.DisplayMessage = '';
        },
        validateInput: function validateInput() {
            var isValid = true;
            if (this.name == "") {
                this.form.NameError = this.form.NameText + " is required.";
                isValid = false;
            } else {
                this.form.NameError = "";
            }
            if (this.email == "" || !this.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.form.EmailError = "Please enter a valid " + this.form.EmailText + ".";
                isValid = false;
            } else {
                this.form.EmailError = "";
            }
            if (this.message == "") {
                this.form.MessageError = this.form.MessageText + " is required.";
                isValid = false;
            } else {
                this.form.MessageError = "";
            }
            // if (this.honeypot) {
            //     this.form.MessageError = "Error"
            //     isValid = false;
            // }
            // var captchaComplete = !!grecaptcha.getResponse(this.formRecaptcha);
            // if(!captchaComplete){
            //     this.form.CaptchaError = "Error with Recaptcha."
            //     isValid = false;
            // }
            this.$forceUpdate();
            return isValid;
        },
        validateVCard: function validateVCard() {
            var isValid = true;
            console.log("vcard");
        },
        downloadVCard: function downloadVCard(url) {
            if (url) {
                var link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                this.toggleVCardModal();
            }
        },
        submit: function submit(professionalId) {
            if (professionalId) {
                this.professionalId = professionalId;
            }
            if (this.validateInput()) {
                var context = this;
                grecaptcha.execute(this.recaptchaPublicKey, { action: 'email_disclaimer' }).then(function (token) {
                    var emailCount = sessionStorage.getItem('emailCount');
                    if (emailCount) {
                        sessionStorage.setItem('emailCount', parseInt(emailCount) + 1);
                    } else {
                        sessionStorage.setItem('emailCount', 1);
                    }
                    Vue.http.post('/api/shared/submitemailform', {
                        ID: context.professionalId,
                        Name: context.name,
                        Email: context.email,
                        Message: context.message,
                        EmailCount: emailCount,
                        RecaptchaResponse: token
                    }).then(function (response) {
                        if (response.status == 200) {
                            if (response.body.success) {
                                context.showMessage(response.body.message);
                            } else if (response.body.recaptchaFailed) {
                                context.form.CaptchaError = "Error with Recaptcha.";
                                isValid = false;
                            } else {
                                console.log(response.body.message);
                            }
                        } else {
                            console.log("error while submitting email");
                            context.showMessage(response.body.message);
                        }
                    }, function (error) {
                        context.showMessage("There was an error submitting the email, please try again later.");
                    });
                }, function (response) {
                    console.log("Error while executing recaptcha");
                });
            }
        },
        submitVCard: function submitVCard(professionalId) {
            if (professionalId) {
                this.professionalId = professionalId;
            }
            var context = this;
            grecaptcha.execute(this.recaptchaPublicKey, { action: 'email_disclaimer' }).then(function (token) {
                Vue.http.post('/api/shared/ValidateCaptcha', {
                    response: token
                }).then(function (response) {
                    if (response && response.body.success) {
                        // return isValid;
                        Vue.http.post('/api/shared/submitvcard', {
                            ID: context.professionalId
                        }).then(function (response) {
                            if (response.status == 200 && response.body.success == true) {
                                context.downloadVCard(response.body.vcardurl);
                            } else {
                                console.log("error while downloading vCard");
                                context.showVCardMessage(response.body.message);
                            }
                        }, function (error) {
                            context.showVCardMessage("There was an error downloading the vCard. Please try again later.");
                        });
                    }
                    if (response && response.body.recaptchaFailed) {
                        isValid = response.body.success;
                        this.vCardForm.CaptchaError = "Error with Recaptcha.";
                    }
                    // this.$forceUpdate();
                    // return isValid;
                }, function (error) {
                    console.log(error);
                });
            }, function (error) {
                console.log(error);
            });
        }
    },

    components: {
        'email-form': {
            template: '#email-form-template',
            props: ['accept', 'decline']
        },
        'v-card': {
            template: '#v-card-template'
        }
    }
};

},{}],5:[function(require,module,exports){
'use strict';

module.exports = {
    el: '#contact-us-app',

    data: function data() {
        return {
            model: undefined,

            typeSelectorInitiated: false,
            inquiryTypes: undefined,
            selectedInquiryType: undefined,
            submitting: false,
            recaptchaPublicKey: '',

            validation: {
                submitTriggered: false,
                showTermsConditionsError: false,
                showRecaptchaError: false,
                showRequiredFieldsError: false,
                showEmailAddressError: false,
                requiredFields: {
                    firstName: false,
                    lastName: false,
                    email: false,
                    message: false
                }
            },

            submission: {
                posted: false,
                success: false
            },

            form: {
                FirstName: '', LastName: '', Email: '',
                Phone: '', JobTitle: '', Company: '',
                Message: '', Captcha: false, TermsConditions: false, MarketingSignup: false,
                InquiryTypeId: '', ContactUsFormId: '',
                Hidden: ''
            }
        };
    },

    created: function created() {
        this.model = window.serializedContactUsDropdown;
        this.inquiryTypes = this.model.inquiryTypes;

        this.form.ContactUsFormId = document.getElementById('contact-us-form').getAttribute('data-form-id');
        this.recaptchaPublicKey = document.getElementById('contact-us-form').getAttribute('data-recaptcha-key');
    },

    methods: {
        submit: function submit() {
            this.submitTriggered = true;
            if (this.validate()) {
                this.submitting = true;
                if (this.selectedInquiryType) {
                    this.form.InquiryTypeId = this.selectedInquiryType.id;
                }
                var context = this;
                grecaptcha.execute(this.recaptchaPublicKey, { action: 'contact_us' }).then(function (token) {
                    context.form.RecaptchaResponse = token;
                    context.$http.post('/api/contactus/submit', context.form).then(function (response) {
                        if (response) {
                            context.submission.posted = true;
                            context.submission.success = response.body.success;
                            context.submitting = false;
                            context.validation.showRecaptchaError = response.body.recaptchaFailed;
                        } else {
                            context.submitting = false;
                            context.submission.success = response;
                            context.validation.showRecaptchaError = response.body.recaptchaFailed;
                        }
                    }, function (error) {
                        console.log(error);
                        context.submitting = false;
                    });
                }, function (error) {
                    console.log(error);
                    context.submitting = false;
                });
            } else {
                setTimeout(function () {
                    errorFields = document.getElementsByClassName('form-validation-error');
                    if (errorFields.length) {
                        errorFields[0].focus();
                    }
                }, 100);
            }
        },

        validate: function validate() {
            if (!this.validation.submitTriggered) {
                this.validation.submitTriggered = true;
            }

            this.validation.requiredFields.firstName = !!this.form.FirstName;
            this.validation.requiredFields.lastName = !!this.form.LastName;
            this.validation.requiredFields.email = !!this.form.Email;
            this.validation.requiredFields.message = !!this.form.Message;

            this.validation.showTermsConditionsError = !this.form.TermsConditions;

            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.validation.showEmailAddressError = this.validation.requiredFields.email && !re.test(this.form.Email); // Email filled out, but wrong

            var required = this.validation.requiredFields;
            this.validation.showRequiredFieldsError = false; // Reset this error if re-submitting
            for (var field in required) {
                if (required.hasOwnProperty(field) && !required[field]) {
                    console.log('Missing required field:', field);
                    this.validation.showRequiredFieldsError = true;
                }
            }

            var captchaComplete;
            // if (this.form.Hidden) {
            //     return false
            // }

            return !(this.validation.showTermsConditionsError || this.validation.showEmailAddressError || this.validation.showRequiredFieldsError);
        }
    },

    computed: {
        emailAddress: function emailAddress() {
            if (!this.selectedInquiryType.contactRecipient) {
                return this.selectedInquiryType.emailOverride;
            }
        },
        firstNameErrorField: function firstNameErrorField() {
            return this.validation.submitTriggered && !this.validation.requiredFields.firstName ? 'error-message-required' : '';
        },
        lastNameErrorField: function lastNameErrorField() {
            return this.validation.submitTriggered && !this.validation.requiredFields.lastName ? 'error-message-required' : '';
        },
        emailErrorField: function emailErrorField() {
            if (this.validation.submitTriggered) {
                if (this.validation.showEmailAddressError) {
                    return 'error-message-email';
                } else if (!this.validation.requiredFields.email) {
                    return 'error-message-required';
                }
            }
        },
        messageErrorField: function messageErrorField() {
            return this.validation.submitTriggered && !this.validation.requiredFields.message ? 'error-message-required' : '';
        },
        termsErrorField: function termsErrorField() {
            return this.validation.submitTriggered && this.validation.showTermsConditionsError ? 'error-message-terms' : '';
        }
    }
};

},{}],6:[function(require,module,exports){
'use strict';

var diversityStore = new Vuex.Store({
    state: {
        showVideo: undefined,
        slidesPaused: undefined
    },
    mutations: {
        closeVideo: function closeVideo(state) {
            state.showVideo = undefined;
            // state.slidesPaused = undefined;
        },
        loadVideo: function loadVideo(state, videoId) {
            state.showVideo = videoId;
            state.slidesPaused = true;
        },
        pauseSlides: function pauseSlides(state) {
            state.slidesPaused = true;
        },
        playSlides: function playSlides(state) {
            state.slidesPaused = undefined;
        }
    }
});
module.exports = {
    el: '#diversity-carousel-app',
    store: diversityStore,
    data: function data() {
        return {
            carousel: window.json.carousel,
            activeSlide: 0,
            slideMove: 'forward',
            player: undefined,
            interval: window.json.carousel.featureRotationInterval
        };
    },
    created: loadAPI(),

    mounted: function mounted() {
        var self = this;
        if (this.interval >= 500) {
            setInterval(function () {
                Vue.nextTick(function () {
                    self.nextSlide(true);
                });
            }, self.interval);
        }
    },
    computed: {
        slideLength: function slideLength() {
            return this.carousel.slides.length;
        },
        isPaused: function isPaused() {
            return this.$store.state.slidesPaused;
        },
        showVideo: function showVideo() {
            this.slidesPaused = this.$store.state.slidesPaused;
            return this.$store.state.showVideo;
        }
    },
    watch: {
        showVideo: function showVideo() {
            if (this.showVideo !== undefined) {
                this.startVideo(this.showVideo);
            }
        }
    },
    methods: {
        activeClass: function activeClass(index) {
            return this.activeSlide ? true : false;
        },
        switchSlides: function switchSlides(slideBackward) {

            slideBackward = slideBackward ? slideBackward : false;
            this.$store.commit('pauseSlides');
            if (slideBackward) {
                this.previousSlide();
            } else {
                this.nextSlide();
            }
        },
        openModal: function openModal(group) {
            this.activeGroup = group;
        },
        closeModal: function closeModal() {
            this.activeGroup = undefined;
        },
        nextSlide: function nextSlide(isInterval) {
            if (!this.isPaused || !isInterval) {
                this.slideMove = 'forward';
                this.activeSlide + 1 == this.slideLength ? this.activeSlide = 0 : this.activeSlide++;
            }
        },
        previousSlide: function previousSlide() {
            this.slideMove = 'backward';

            this.activeSlide == 0 ? this.activeSlide = this.slideLength - 1 : this.activeSlide--;
        },
        playVideo: function playVideo(videoId) {
            this.$store.commit('loadVideo', videoId);
        },
        afterLeave: function afterLeave(el) {
            //force class removal
            el.classList.remove('slide-leave-to');
        },
        pauseVideo: function pauseVideo() {
            if (this.isPaused) {
                this.$store.commit('playSlides');
            } else {
                this.$store.commit('pauseSlides');
            }
        },
        skipToSlide: function skipToSlide(index) {
            this.$store.commit('pauseSlides');
            this.activeSlide = index;
        },
        pauseAndCloseVideoPlayer: function pauseAndCloseVideoPlayer() {
            this.$store.commit('closeVideo');
            if (this.player) this.player.pauseVideo();else console.log("No video available");
        },
        startVideo: function startVideo(videoId) {
            if (this.player) {
                if (this.player.getVideoData().video_id !== videoId) this.player.loadVideoById(videoId);
                this.player.playVideo();
            } else {
                this.player = new YT.Player('video-iframe', {
                    videoId: videoId,
                    host: 'https://www.youtube.com',
                    frameborder: 0,
                    height: '390',
                    width: '640',
                    playerVars: { autoplay: 1, showinfo: 0 },
                    events: {
                        'onReady': function onReady() {},
                        'onStateChange': function onStateChange() {}
                    }
                });
            }
        }
    }
};
function loadAPI() {
    var firstScriptTag;
    var tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

},{}],7:[function(require,module,exports){
'use strict';

module.exports = {
  el: '#featured-people-app',

  data: {
    featuredPeople: JSON.parse(window.initialData.featuredPeople),
    index: 0
  },

  computed: {
    slideLength: function slideLength() {
      return this.featuredPeople.length;
    },

    showSlider: function showSlider() {
      return this.slideLength > 1;
    }
  },

  methods: {
    slideStyles: function slideStyles(slide) {
      return { transform: 'translateX(' + this.index * -100 + '%)' };
    },
    nextSlide: function nextSlide() {
      this.index = (this.index + 1) % this.slideLength;
    }
  }
};

},{}],8:[function(require,module,exports){
'use strict';

var directions = {
	37: -1, // Arrow left
	39: 1 // Arrow right
};

module.exports = {
	el: '#gold-tabs-app',

	data: {
		activeTab: 0,
		panelsLength: 0,
		showMobileNav: false
	},

	mounted: function mounted() {
		this.panelsLength = this.$refs.tabframe.dataset.panelsLength;
	},

	computed: {
		activeLabel: function activeLabel() {
			var tabEl = this.$refs['tabButton-' + this.activeTab];
			return tabEl ? tabEl.innerText : '';
		}
	},

	methods: {
		selectTab: function selectTab(givenIndex) {
			// Clamp the tab index so it's not out of bounds
			this.activeTab = Math.min(this.panelsLength - 1, Math.max(0, givenIndex));
			this.showMobileNav = false;
		},

		onTabKeydown: function onTabKeydown(event, givenIndex) {
			var key = event.keyCode;
			var dir = directions[key];

			if (dir !== undefined) {
				var toIndex = givenIndex + dir;
				this.selectTab(toIndex);

				var toTabButton = this.$refs['tabButton-' + toIndex];
				if (toTabButton) {
					toTabButton.focus();
				}
			}
		},

		toggleMobileNav: function toggleMobileNav() {
			this.showMobileNav = !this.showMobileNav;
		}
	}
};

},{}],9:[function(require,module,exports){
'use strict';

if (document.getElementById('locations-app')) {
  var error = function error(func, message) {
    throw new Error('ERROR: [' + func.name + '] > ' + message);
  };

  var utilities = require('../../app/location-utilities');

  var region = new Vue({
    el: '#locations-app',

    data: function data() {
      return {
        keyword: '',
        executed: false,
        locations: [],
        displayCount: 0,
        displayedLocations: [],
        offices: []
      };
    },

    mounted: function mounted() {
      this.offices = JSON.parse(window.initialData.offices).map(function (office) {
        return {
          id: office.id,
          latLng: { lat: office.lat, lng: office.lng }
        };
      });

      this.locations = JSON.parse(window.initialData.locations);
      this.displayCount = parseInt(window.initialData.displayCount) || 4;
      this.reset();
    },

    methods: {
      search: function search() {
        if (this.keyword) {
          this.executed = true;
          var app = this;
          utilities.geocode(this.keyword, function (address) {
            if (address) {
              utilities.getNearestOffice(address, app.offices, function (nearestOffice) {
                if (nearestOffice) {
                  var location = app.getLocationByOffice(nearestOffice);
                  app.displayedLocations = [location];
                } else {
                  error(utilities.getNearestOffice, "An error occurred while trying to find the nearest office to " + address + ".");
                }
              });
            } else {
              error(utilities.geocode, "An error occurred while trying to geocode " + address + ".");
            }
          });
        } else {
          this.clear();
        }
      },

      getLocationByOffice: function getLocationByOffice(office) {
        var location = this.locations.find(function (location) {
          return location.officeId === office.id;
        });

        if (location) return location;else error(this.getLocationByOffice, "Could not find a location that matches office ID " + office.id + ".");
      },

      reset: function reset() {
        this.keyword = '';
        this.displayedLocations = this.locations.slice(0, this.displayCount);
        this.executed = false;
      }
    },
    watch: {
      keyword: function keyword(val) {
        if (this.executed && !val) {
          this.reset();
        }
      }
    }
  });
}

},{"../../app/location-utilities":1}],10:[function(require,module,exports){
'use strict';

var ScreenRead = require('./screen-read');

module.exports = {
    el: '#office-listing-app',

    data: function data() {
        return {
            offices: null,
            regionFilters: null,
            selectedFilter: null,
            officesLoaded: false,
            dropdown: { shown: false, selected: null }
        };
    },

    created: function created() {
        this.regionFilters = window.serializedRegionFilters;
        this.selectedFilter = this.regionFilters[0];
        this.offices = window.serializedOffices;
        this.officesLoaded = true;
    },

    methods: {
        changeSelectedRegion: function changeSelectedRegion(regionId) {
            var filter = this.getRegionFilter(regionId);
            this.selectedFilter = filter;
            this.getOffices(regionId);
            this.toggleDropdown();
        },

        getOffices: function getOffices(regionId, callback) {
            this.officesLoaded = false;
            this.$http.get('/api/locations/officesbyregion?regionId=' + regionId).then(function (response) {
                this.offices = response.body.offices;
                this.officesLoaded = true;
                if (callback) callback(this.offices);
            }, function (error) {
                console.log(error);
            });
        },

        toggleDropdown: function toggleDropdown() {
            this.dropdown.shown = !this.dropdown.shown;
        },

        getRegionFilter: function getRegionFilter(regionId) {
            for (var i = 0; i < this.regionFilters.length; i++) {
                var currentFilter = this.regionFilters[i];
                if (currentFilter.id == regionId) return currentFilter;
            }
        },
        toTwoPlaces: function toTwoPlaces(num) {
            return num < 10 ? "0" + num : num;
        }
    },

    computed: {
        seconds: function seconds(int) {
            return "sh-" + int;
        },

        minutes: function minutes(int) {
            return "mh-" + int;
        },

        hours: function hours(int) {
            return "hh-" + int;
        }
    },

    components: {
        'clock': {
            template: '#clock-template',
            props: ['seconds', 'minutes', 'hours'],
            computed: {
                computedHours: function computedHours() {
                    var currentHours = this.hours;
                    if (currentHours >= 12) {
                        currentHours = currentHours - 12;
                    }
                    return Math.floor((currentHours + this.minutes / 60) * 5);
                }
            }
        },
        'ScreenRead': ScreenRead
    }
};

},{"./screen-read":13}],11:[function(require,module,exports){
'use strict';

var directions = {
    37: -1, // Arrow left
    39: 1 // Arrow right
};

module.exports = {
    el: '#related-articles-app',

    data: function data() {
        return {
            model: null,
            panels: null,
            articles: null,
            context: {
                articlesLoaded: false,
                moreArticles: false,
                selectedPanel: null
            },
            dropdown: {
                shown: false,
                selected: null
            }
        };
    },

    created: function created() {
        this.model = window.relatedArticlesDataModel;
        this.getPanels(this.getArticles);
    },

    methods: {
        getPanels: function getPanels(callback) {
            this.panels = this.model.articlePanels;
            this.context.selectedPanel = this.panels[0];
            if (callback) callback(this.context.selectedPanel.id, 0, this.model.displayCount);
        },

        getArticles: function getArticles(panelId, skip, take, reload, callback) {
            var request = '/api/shared/relatedarticles' + '?panelId=' + panelId + '&entityId=' + this.model.entityId;

            if (skip) request = request + '&' + 'skip=' + skip;
            if (take) request = request + '&' + 'take=' + take;

            this.$http.get(request).then(function (response) {
                if (!this.articles || reload) {
                    this.articles = response.body.articles;
                    this.context.moreArticles = response.body.moreArticles;
                    this.context.articlesLoaded = true;
                    window.vueAppStates.perspectivesNewsEvents = true;
                } else {
                    var receivedArticles = response.body.articles;
                    this.context.moreArticles = response.body.moreArticles;
                    if (callback) callback(receivedArticles);
                }
            }, function (error) {
                console.log(error);
            });
        },

        loadMoreArticles: function loadMoreArticles(panelId) {
            var skip = this.articles.length;
            this.getArticles(panelId, skip, this.model.viewMoreCount, false, this.addReceivedArticles);
        },

        selectPanel: function selectPanel(panelId) {
            if (this.context.selectedPanel.id != panelId) {
                this.context.selectedPanel = this.getPanelById(panelId);
                this.context.selectedPanel.Name = panelId;
                this.getArticles(this.context.selectedPanel.id, 0, this.model.displayCount, true);
            }
        },

        addReceivedArticles: function addReceivedArticles(receivedArticles) {
            for (var i = 0; i < receivedArticles.length; i++) {
                this.articles.push(receivedArticles[i]);
            }
        },

        toggleDropdown: function toggleDropdown() {
            this.dropdown.shown = !this.dropdown.shown;
        },

        getPanelById: function getPanelById(panelId) {
            for (var i = 0; i < this.panels.length; i++) {
                var panel = this.panels[i];
                if (panel.id == panelId) return panel;
            }
        },

        getLinkTarget: function getLinkTarget(isExternalLink) {
            return isExternalLink ? "_blank" : "_self";
        },

        onTabKeydown: function onTabKeydown(event, tabIndex) {
            var key = event.keyCode;
            var dir = directions[key];

            if (dir !== undefined) {
                var toIndex = tabIndex + dir;
                var toPanel = this.panels[toIndex];
                var toTabButton = this.$refs.tabButton[toIndex];

                if (toPanel && toTabButton) {
                    this.selectPanel(toPanel.id);
                    toTabButton.focus();
                }
            }
        }
    },

    components: {
        'article-tile': {
            template: '#article-tile-template',
            props: ['id', 'featured', 'external', 'url', 'title', 'date', 'multimedia', 'source', 'presentationtitle', 'location', 'type', 'image', 'getlinktarget', 'featuredlabel']
        }
    }
};

},{}],12:[function(require,module,exports){
'use strict';

module.exports = {
    el: '#related-experience-app',

    data: function data() {
        return {
            model: null,
            experienceGroups: [],
            experiences: [],
            firstSet: [],
            dropdown: { shown: false, selected: null },
            moreExperiences: false,
            loadedMore: false
        };
    },

    created: function created() {
        this.model = window.relatedExperienceDataModel;
        this.getExperienceGroups(this.getExperiences);
    },

    methods: {
        viewLess: function viewLess() {
            this.experiences = JSON.parse(JSON.stringify(this.firstSet));
            this.loadedMore = false;
            this.moreExperiences = true;
        },

        getExperienceGroups: function getExperienceGroups(callback) {
            this.experienceGroups = this.model.experienceGroups;
            this.dropdown.selected = this.experienceGroups[0];
            if (callback) callback(this.dropdown.selected.id, 0, this.model.displayCount, this.resetExperiences, false);
        },

        getExperiences: function getExperiences(selectedGroupId, skip, take, callback, toggleDropdown) {
            var requestUrl = this.buildRequestUrl(selectedGroupId, skip, take);

            this.$http.get(requestUrl).then(function (response) {
                this.moreExperiences = response.body.moreExperiences;
                var addedExperiences = response.body.relatedExperiences.filter(function (exp) {
                    return exp.ID != '00000000-0000-0000-0000-000000000000';
                });
                callback(addedExperiences);
                if (toggleDropdown) this.toggleDropdown();
                window.vueAppStates.relatedExperience = true;
            }, function (error) {
                console.log(error);
            });
        },

        changeExperienceGroup: function changeExperienceGroup(experienceGroupId) {
            this.loadedMore = false;
            if (!this.dropdown.selected || this.dropdown.selected.id != experienceGroupId) {
                this.dropdown.selected = this.getExperienceGroupById(experienceGroupId);
                this.getExperiences(this.dropdown.selected.id, 0, this.model.displayCount, this.resetExperiences, true);
            }
        },

        buildRequestUrl: function buildRequestUrl(selectedGroupId, skip, take) {
            var requestUrl = '/api/shared/relatedexperience?experienceGroupId=' + selectedGroupId;
            if (skip) requestUrl += '&skip=' + skip;
            if (take) requestUrl += '&take=' + take;
            if (this.model.entityId) requestUrl += '&entityId=' + this.model.entityId;
            if (this.experienceGroups) requestUrl += '&numExperienceGroups=' + this.experienceGroups.length;
            return requestUrl;
        },

        showMoreExperiences: function showMoreExperiences() {
            if (!this.loadedMore) {
                this.firstSet = JSON.parse(JSON.stringify(this.experiences));
                this.loadedMore = true;
            }
            var currentlyDisplayed = this.experiences.length;
            this.getExperiences(this.dropdown.selected.id, currentlyDisplayed, this.model.viewMoreCount, this.addExperiences, false);
        },

        resetExperiences: function resetExperiences(addedExperiences) {
            this.experiences = addedExperiences;
        },

        addExperiences: function addExperiences(addedExperiences) {
            this.experiences.pushArray(addedExperiences);
        },

        toggleDropdown: function toggleDropdown() {
            this.dropdown.shown = !this.dropdown.shown;
        },

        getExperienceGroupById: function getExperienceGroupById(groupId) {
            for (var i = 0; i < this.experienceGroups.length; i++) {
                var group = this.experienceGroups[i];
                if (group.id == groupId) return group;
            }
        }
    }
};

},{}],13:[function(require,module,exports){
'use strict';

module.exports = {
  template: '<span class="sr-only" role="timer" v-bind:aria-live="ariaLiveMode"><time>{{output}}</time></span>',
  data: function data() {
    return {
      screenReadTime: new Date()
    };
  },
  props: ['seconds', 'minutes', 'hours', 'mode'],
  created: function created() {
    this.screenReadTime = new Date();
    this.screenReadTime.setHours(this.hours);
    this.screenReadTime.setMinutes(this.minutes);
    this.screenReadTime.setSeconds(this.seconds);

    var start = function () {
      this.updateTime();
      // Update every minute on the minute
      setInterval(this.updateTime, 60000);
    }.bind(this);

    // Wait for the next minute to start
    setTimeout(start, (60 - this.seconds) * 1000);
  },
  computed: {
    output: function output() {
      var hours = this.toTwoPlaces(this.screenReadTime.getHours());
      var minutes = this.toTwoPlaces(this.screenReadTime.getMinutes());
      return hours + ':' + minutes;
    },
    ariaLiveMode: function ariaLiveMode() {
      return this.mode === 'loud' ? 'polite' : null;
    }
  },
  methods: {
    toTwoPlaces: function toTwoPlaces(num) {
      return num < 10 ? "0" + num : num;
    },
    updateTime: function updateTime() {
      this.screenReadTime.setMinutes(this.screenReadTime.getMinutes() + 1);
      this.screenReadTime = new Date(this.screenReadTime.getYear(), this.screenReadTime.getMonth(), this.screenReadTime.getDate(), this.screenReadTime.getHours(), this.screenReadTime.getMinutes(), 0);
    }
  }
};

},{}],14:[function(require,module,exports){
"use strict";

var utilities = {
		defaultElement: '#search-top',

		defaultScroll: function defaultScroll() {
				var offset = parseInt($(window).height() / 2);
				var location = parseInt($(this.defaultElement).offset().top);

				$("html, body").animate({
						scrollTop: location - offset
				}, "slow", function () {
						var $resultCount = $('.searched-criteria-results');
						$resultCount.focus().attr('tabindex', -1);
				});

				return location - offset;
		},

		scrollTo: function scrollTo(location) {
				$("html, body").animate({
						scrollTop: location
				}, "slow");
		}
};

module.exports = {
		el: '#site-search-results',

		data: function data() {
				return {
						model: {},
						results: [],
						context: {
								currentlyDisplayedCount: 0,
								remainingResults: false,
								resultsStartIndex: 0,
								searching: false,
								selectedFilter: {},
								totalResults: 0,
								suggestion: null
						},
						constants: {
								baseRequest: '/api/sitesearch/search',
								limitFilterFormat: 'more:pagemap:metatags-limitfilter:{0}',
								languageFilterFormat: 'more:pagemap:metatags-languagefilter:{0}',
								queryKey: 'query'
						}
				};
		},

		created: function created() {
				this.model = window.siteSearchResultsModel;
				this.results = this.model.serverResults && this.model.serverResults.items ? this.model.serverResults.items : [];
				this.context.currentlyDisplayedCount = this.results ? this.results.length : 0;
				this.context.selectedFilter = this.model.filters[0];
				this.setContext(this.model.serverResults);

				var app = this;
				window.setTimeout(function () {
						var scrollUser = false;

						if (window.location.search.indexOf(app.constants.queryKey) >= 0) {
								var queryParams = window.getQueryParams();
								var query = queryParams[app.constants.queryKey];
								if (query && query !== '') {
										utilities.defaultScroll();
								}
						}
				}, 1000);
		},

		updated: function updated() {
				// Set focus on the first new result after loading more
				var firstResultItem = this.$refs.resultListItems ? this.$refs.resultListItems[this.context.resultsStartIndex] : undefined;
				var firstResultLink = firstResultItem !== undefined ? firstResultItem.firstChild : undefined;
				if (firstResultLink !== undefined) {
						firstResultLink.focus();
				}
		},

		methods: {
				executeSearch: function executeSearch(start, take, callback) {
						var request = this.buildRequest(start, take);
						var app = this;
						this.$http.get(request).then(function (response) {
								var results = response.body.results;
								app.setContext(results);
								callback(results);

								document.getElementsByTagName("title")[0].innerHTML = response.body.titleTag;
						}, function () {
								console.log(error);
						});
				},

				handleFilterClick: function handleFilterClick(limitFilter) {
						this.context.searching = true;
						this.context.selectedFilter = limitFilter;
						//this.limitFilter = limitFilter; 
						//this.selectFilter(limitFilter);

						var app = this;
						this.executeSearch(1, this.model.initialDisplayCount, function (results) {
								if (results && results.items) {
										app.results = results.items;
										app.context.currentlyDisplayedCount = app.results.length;
								} else {
										app.results = [];
										app.context.currentlyDisplayedCount = 0;
								}

								app.context.searching = false;
								if (app.context.mobileMenuExpanded) {
										app.context.mobileMenuExpanded = false;
								}
								//utilities.defaultScroll();
						});
				},

				useSuggestion: function useSuggestion() {
						var app = this;

						app.query = this.context.suggestion;
						app.context.searching = true;
						app.model.searchQuery = this.context.suggestion;

						this.executeSearch(1, this.model.initialDisplayCount, function (results) {
								if (results && results.items) {
										app.results = results.items;
										app.context.currentlyDisplayedCount = app.results.length;
								} else {
										app.results = [];
										app.context.currentlyDisplayedCount = 0;
								}

								//utilities.defaultScroll();
						});
				},

				handleRemoveFilterClick: function handleRemoveFilterClick(type) {
						if (type == 'query') {
								this.model.searchQuery = '';
								this.handleFilterClick(this.context.selectedFilter.limitFilter);
						} else if (type == 'filter') {
								this.handleFilterClick({ limitFilter: 'all', name: 'ALL' });
						}
				},

				handleLoadMoreResultsClick: function handleLoadMoreResultsClick() {
						var app = this;
						this.executeSearch(this.context.currentlyDisplayedCount + 1, this.model.viewModel.viewMoreCount, function (results) {
								if (results && results.items) {
										app.context.resultsStartIndex = app.results.length;
										app.results.pushArray(results.items);
										app.context.currentlyDisplayedCount += results.items.length;
								}
						});
				},

				handleTypeAheadSearchQuery: function handleTypeAheadSearchQuery(typeAheadQuery) {
						var app = this;
						app.model.searchQuery = typeAheadQuery;
						this.executeSearch(1, this.model.initialDisplayCount, function (results) {
								if (results && results.items) {
										app.results = results.items;
										app.context.currentlyDisplayedCount = app.results.length;
								} else {
										app.results = [];
										app.context.currentlyDisplayedCount = 0;
								}

								//utilities.defaultScroll();
						});
				},

				buildRequest: function buildRequest(start, take) {
						return this.constants.baseRequest + '?query=' + encodeURIComponent(this.query) + '&limitfilter=' + this.limitFilter + '&languagefilter=' + this.languageFilter + '&start=' + start + '&take=' + take;
				},

				setContext: function setContext(results) {
						this.context.totalResults = results.totalResults > 0 ? results.totalResults : 0;

						if (!!results.suggestion) {
								this.context.suggestion = results.suggestion;
						} else {
								this.context.suggestion = null;
						}

						this.context.remainingResults = results.remainingResults > 0 ? results.remainingResults : 0;
				}
		},

		computed: {
				query: function query() {
						return this.model.searchQuery;
				},

				validQuery: function validQuery() {
						return this.model.searchQuery && this.model.searchQuery !== '' || this.context.selectedFilter && this.context.selectedFilter.limitFilter !== 'all';
				},

				totalResultsNotice: function totalResultsNotice() {
						if (this.results) {
								return this.model.viewModel.resultCountFormat.format(this.context.totalResults);
						} else {
								return this.model.viewModel.noResultsLabel;
						}
				},

				resultsVisibility: function resultsVisibility() {
						return this.context.searching ? 'hidden' : 'visible';
				},

				limitFilter: function limitFilter() {
						if (this.context.selectedFilter && this.context.selectedFilter.limitFilter !== 'all') {
								return this.context.selectedFilter.limitFilter;
						}
						return "";
				},

				languageFilter: function languageFilter() {
						if (this.model.viewModel.currentLanguageCode) {
								return this.model.viewModel.currentLanguageCode;
						}
						// Re-Add Language Filter after the meta tags go live.
						// if (this.model.viewModel.currentLanguageCode) {
						//     queryParts.push(this.constants.languageFilterFormat.format(this.model.viewModel.currentLanguageCode));
						// }
						return "";
				}
		},

		components: {
				'search-typeahead': {
						template: '#search-typeahead-sitesearch',
						extends: VueTypeahead.default,
						props: ['placeholdertext', 'arialabel', 'defaultstatus', 'populatedstatus', 'emptystatus'],
						created: function created() {
								this.model = window.siteSearchResultsModel;
						},
						data: function data() {
								return {
										model: {},
										src: '/api/sitesearch/typeahead',
										limit: window.siteSearchResultsModel.viewModel.typeAheadTake || 5,
										minChars: 3,
										queryParamName: 'term',
										context: {
												mobileMenuExpanded: false,
												selectedFilter: {
														name: 'All'
												}
										},
										lastQuery: '',
										lastCurrent: -1
								};
						},
						computed: {
								status: function status() {
										if (this.query.length >= this.minChars && !this.loading) {
												if (this.hasItems) {
														return this.populatedstatus.replace(/\{0\}/g, this.items.length);
												} else {
														return this.emptystatus;
												}
										} else {
												return this.defaultstatus.replace(/\{0\}/g, this.minChars);
										}
								},
								currentTitle: function currentTitle() {
										var currentItem = this.items[this.current];
										return currentItem ? currentItem.Title : '';
								}
						},
						methods: {
								onHit: function onHit(item) {
										window.location.href = item.Url;
								},
								search: function search() {
										if (this.query !== '') {
												this.$emit('setsearchquery', this.query);
												this.clearTypeahead();
										}
								},
								goToResult: function goToResult() {
										if (this.current >= 0 && this.hasItems) {
												window.location.href = this.items[this.current].Url;
										} else if (this.query !== '') {
												this.$emit('setsearchquery', this.query);
												this.clearTypeahead();
										}
								},
								selectFilter: function selectFilter(limitFilter) {
										for (var i = 0; i < this.model.filters.length; i++) {
												var filter = this.model.filters[i];
												if (filter.limitFilter === limitFilter) {
														this.context.selectedFilter = this.model.filters[i];
												}
										}
								},
								filterClick: function filterClick(limitFilter) {
										this.selectFilter(limitFilter);
										this.context.mobileMenuExpanded = false;
										this.$emit('setfilterchange', this.context.selectedFilter);
								},
								beforeNav: function beforeNav() {
										this.lastCurrent = this.current;
										if (this.current === -1) {
												this.lastQuery = this.query;
										}
								},
								afterNav: function afterNav() {
										if (this.current !== -1) {
												this.query = this.currentTitle;
										} else {
												this.query = this.lastQuery;
										}
								},
								updateCustom: function updateCustom() {
										if (this.current === -1) {
												this.update();
										}
								},
								upCustom: function upCustom() {
										this.beforeNav();
										this.up();
										this.afterNav();
								},
								downCustom: function downCustom() {
										this.beforeNav();
										this.down();
										this.afterNav();
								},
								resetCustom: function resetCustom() {
										this.reset();
										this.current = -1;
										this.lastCurrent = -1;
										this.lastQuery = '';
								},
								clearTypeahead: function clearTypeahead() {
										this.items = [];
										this.current = -1;
										this.lastCurrent = -1;
										this.lastQuery = '';
								}
						}
				}
		}
};

},{}],15:[function(require,module,exports){
'use strict';

if (document.getElementById('articles-landing')) {
	// Recent Articles
	var recentArticlesUtility = {
		search: function search(apiUrl, take, page) {
			var searchUrl = apiUrl + ('?skip=' + (page - 1) * take + '&take=' + take);
			return Vue.http.post(searchUrl).then(function (response) {

				if (response.status == 200) {
					return response.body;
				} else {
					console.log("error while retrieving search results");
					return defaultResults;
				}
			}, function (error) {
				console.log(error);
			});
		}
	};

	var landingSettingsDefaultValues = {
		recentArticles: landingServerJson.RecentArticles || [],
		recentArticleCount: landingServerJson.RecentArticleCount || 0,
		recentArticlesPageSize: landingServerJson.RecentArticlesPageSize,
		recentArticlesApiUrl: landingServerJson.RecentArticlesApiUrl,
		searchApiUrl: landingServerJson.SearchApiUrl,
		typeAheadApiUrl: landingServerJson.TypeAheadApiUrl,
		headerText: landingServerJson.RecentArticleHeaderText || '',
		loadMoreText: landingServerJson.LoadMoreText || 'View More',
		templateName: landingServerJson.LandingPageTemplateName,
		recentArticlesPage: 1

		// Article search
	};var utilities = {
		defaultElement: '#search-landing-filters',
		loadMoreElement: '#load-more-button',
		upcomingLoadMoreElement: '#upcoming-load-more-button',
		perspectiveFilters: ['capability', 'topic', 'location', 'type', 'time'],
		eventFilters: ['capability', 'location', 'type', 'time', 'topic', 'date'],
		newsFilters: ['capability', 'topic', 'location', 'type', 'time'],
		defaultResults: {
			ResultsCount: 0,
			Results: []
		},
		emptyValues: {
			results: [],
			resultCount: 0,
			searching: false,
			page: searchServerJson.Page || 1,
			upcomingPage: searchServerJson.UpcomingPage || 1,
			take: searchServerJson.Take || 25,
			upcomingTake: searchServerJson.upcomingTake || 10,
			searchKeyword: '',
			scrollPosition: 0,
			capabilityFilters: searchServerJson.CapabilityFilters,
			locationFilters: searchServerJson.LocationFilters,
			topicFilters: searchServerJson.TopicFilters,
			typeFilters: searchServerJson.TypeFilters,
			dateFilters: searchServerJson.DateFilters,
			selectedProfessionalFilter: { FilterId: '', Name: '' },
			selectedCapabilityFilter: { FilterId: '', Name: '' },
			selectedLocationFilter: { FilterId: '', Name: '' },
			selectedTopicFilter: { FilterId: '', Name: '' },
			selectedTypeFilter: { FilterId: '', Name: '' },
			selectedDateFilter: { FilterId: '', Name: '' },
			selectedEventDateFilter: ''
		},
		defaultValues: {
			results: searchServerJson.ArticleSearchResults.Results || [],
			resultCount: searchServerJson.ArticleSearchResults.ResultCount || 0,
			upcomingResultCount: searchServerJson.ArticleSearchResults.UpcomingResultCount || 0,
			upcomingResults: searchServerJson.ArticleSearchResults.UpcomingResults || [],
			searching: searchServerJson.Searching || false,
			page: searchServerJson.Page || 1,
			take: searchServerJson.Take || 25,
			upcomingPage: searchServerJson.UpcomingPage,
			upcomingTake: searchServerJson.UpcomingTake || 10,
			searchKeyword: searchServerJson.SearchKeyword || '',
			scrollPosition: searchServerJson.Scroll || 0,
			capabilityFilters: searchServerJson.CapabilityFilters,
			locationFilters: searchServerJson.LocationFilters,
			topicFilters: searchServerJson.TopicFilters,
			typeFilters: searchServerJson.TypeFilters,
			dateFilters: searchServerJson.DateFilters,
			selectedProfessionalFilter: !!searchServerJson.ProfessionalFilter ? searchServerJson.ProfessionalFilter : {
				Name: "",
				FilterId: ""
			},
			selectedCapabilityFilter: searchServerJson.CapabilityFilters.find(function (item) {
				return item.Selected;
			}) || {
				Name: "",
				FilterId: ""
			},
			selectedLocationFilter: searchServerJson.LocationFilters.find(function (item) {
				return item.Selected;
			}) || {
				Name: "",
				FilterId: ""
			},
			selectedTopicFilter: searchServerJson.TopicFilters.find(function (item) {
				return item.Selected;
			}) || {
				Name: "",
				FilterId: ""
			},
			selectedTypeFilter: searchServerJson.TypeFilters.find(function (item) {
				return item.Selected;
			}) || {
				Name: "",
				FilterId: ""
			},
			selectedDateFilter: searchServerJson.DateFilters.find(function (item) {
				return item.Selected;
			}) || {
				Name: "",
				FilterId: ""
			},
			selectedEventDateFilter: searchServerJson.SelectedEventDate || ''
		},
		clearDropdownSelection: function clearDropdownSelection(id) {
			formattedId = '#' + id;
			$(formattedId).val('');
		},
		getLoadMoreLocation: function getLoadMoreLocation() {
			return parseInt($(this.loadMoreElement).offset().top);
		},
		getUpcomingLoadMoreLocation: function getUpcomingLoadMoreLocation() {
			return parseInt($(this.upcomingLoadMoreElement).offset().top);
		},
		getParams: function getParams(url) {
			var searchParams = url;

			if (searchParams.length == 0) {
				return [];
			}

			searchParams = searchParams.substr(1);

			var params = searchParams.split('&');

			var objects = params.map(function (equation) {
				var sides = equation.split('=');
				return {
					key: sides[0],
					value: sides[1]
				};
			});

			return objects;
		},
		getNewUrlParams: function getNewUrlParams(paramsList) {
			var paramPairs = paramsList.map(function (param) {
				return param.key + '=' + param.value;
			});

			var newUrlParams = '?' + paramPairs.join('&');

			return window.location.pathname + newUrlParams;
		},
		setUrlParameter: function setUrlParameter(key, value, url) {
			var params = this.getParams(url);

			var paramsWithKey = params.filter(function (param) {
				return param.key === key;
			});

			var paramsWithoutKey = params.filter(function (param) {
				return param.key !== key;
			});

			var paramWithKey = paramsWithKey[0];

			var paramExists = paramWithKey !== undefined;

			if (paramExists) {
				paramWithKey.value = value;
			} else {
				paramWithKey = {
					key: key,
					value: value
				};
			}

			var newParams = paramsWithoutKey;

			if (value != undefined) newParams.push(paramWithKey);

			return this.getNewUrlParams(newParams);
		},
		defaultScroll: function defaultScroll() {
			var offset = parseInt($(window).height() / 2);

			var location = parseInt($(this.defaultElement).offset().top);
			$("html, body").animate({
				scrollTop: location - offset
			}, "slow", function () {
				var $resultCount = $('.searched-criteria-results');
				$resultCount.focus().attr('tabindex', -1);
			});

			return location - offset;
		},
		scrollTo: function scrollTo(location) {
			$("html, body").animate({
				scrollTop: location
			}, "slow");
		},
		search: function search(apiUrl, page, take, upcomingPage, upcomingTake, keyword, eventDate, dateFilter, location, capability, topic, type, professional) {
			return Vue.http.post(apiUrl, {
				Skip: (page - 1) * take,
				Take: take,
				UpcomingSkip: (upcomingPage - 1) * upcomingTake,
				UpcomingTake: upcomingTake,
				Keyword: keyword,
				EventDate: eventDate,
				DateFilter: dateFilter,
				Location: location,
				Capability: capability,
				Topic: topic,
				Type: type,
				Professional: professional
			}).then(function (response) {

				if (response.status == 200) {
					return response.body;
				} else {
					console.log("error while retrieving search results");
					return defaultResults;
				}
			}, function (error) {
				console.log(error);
			});
		}

	};

	Vue.use(VueResource);

	var store = new Vuex.Store({

		state: {
			results: utilities.defaultValues.results,
			resultCount: utilities.defaultValues.resultCount,
			searching: utilities.defaultValues.searching,
			page: utilities.defaultValues.page,
			take: utilities.defaultValues.take,
			upcomingPage: utilities.defaultValues.upcomingPage,
			upcomingTake: utilities.defaultValues.upcomingTake,
			searchKeyword: utilities.defaultValues.searchKeyword,
			scrollPosition: utilities.defaultValues.scrollPosition,
			capabilityFilters: utilities.defaultValues.capabilityFilters,
			locationFilters: utilities.defaultValues.locationFilters,
			topicFilters: utilities.defaultValues.topicFilters,
			typeFilters: utilities.defaultValues.typeFilters,
			dateFilters: utilities.defaultValues.dateFilters,
			selectedProfessionalFilter: utilities.defaultValues.selectedProfessionalFilter,
			selectedCapabilityFilter: utilities.defaultValues.selectedCapabilityFilter,
			selectedLocationFilter: utilities.defaultValues.selectedLocationFilter,
			selectedTopicFilter: utilities.defaultValues.selectedTopicFilter,
			selectedTypeFilter: utilities.defaultValues.selectedTypeFilter,
			selectedDateFilter: utilities.defaultValues.selectedDateFilter,
			selectedEventDateFilter: utilities.defaultValues.selectedEventDateFilter,
			upcomingResultCount: utilities.defaultValues.upcomingResultCount,
			upcomingResults: utilities.defaultValues.upcomingResults
		},

		getters: {
			selectedEventDateFilter: function selectedEventDateFilter(state) {
				return state.selectedEventDateFilter;
			},
			results: function results(state) {
				return state.results;
			},
			resultCount: function resultCount(state) {
				return state.resultCount;
			},
			upcomingResultCount: function upcomingResultCount(state) {
				return state.upcomingResultCount;
			},
			upcomingResults: function upcomingResults(state) {
				return state.upcomingResults;
			},
			searching: function searching(state) {
				return state.searching;
			},
			upcomingTake: function upcomingTake(state) {
				return state.upcomingTake;
			},
			upcomingPage: function upcomingPage(state) {
				return state.upcomingPage;
			},
			page: function page(state) {
				return state.page;
			},
			take: function take(state) {
				return state.take;
			},
			searchKeyword: function searchKeyword(state) {
				return state.searchKeyword;
			},
			scrollPosition: function scrollPosition(state) {
				return state.scrollPosition;
			},
			capabilityFilters: function capabilityFilters(state) {
				return state.capabilityFilters;
			},
			locationFilters: function locationFilters(state) {
				return state.locationFilters;
			},
			topicFilters: function topicFilters(state) {
				return state.topicFilters;
			},
			typeFilters: function typeFilters(state) {
				return state.typeFilters;
			},
			dateFilters: function dateFilters(state) {
				return state.dateFilters;
			},
			selectedProfessionalFilter: function selectedProfessionalFilter(state) {
				return state.selectedProfessionalFilter;
			},
			selectedCapabilityFilter: function selectedCapabilityFilter(state) {
				return state.selectedCapabilityFilter;
			},
			selectedLocationFilter: function selectedLocationFilter(state) {
				return state.selectedLocationFilter;
			},
			selectedTopicFilter: function selectedTopicFilter(state) {
				return state.selectedTopicFilter;
			},
			selectedTypeFilter: function selectedTypeFilter(state) {
				return state.selectedTypeFilter;
			},
			selectedDateFilter: function selectedDateFilter(state) {
				return state.selectedDateFilter;
			}
		},

		mutations: {
			updateSelectedEventDateFilter: function updateSelectedEventDateFilter(state, date) {
				state.selectedEventDateFilter = date;
			},
			resetState: function resetState(state) {
				var defaults = utilities.emptyValues;
				for (var key in defaults) {
					state[key] = defaults[key];
				}
				utilities.clearDropdownSelection('field-capability');
				utilities.clearDropdownSelection('field-location');
				utilities.clearDropdownSelection('field-type');
				utilities.clearDropdownSelection('field-time-period');
			},
			updateSelectedCapabilityFilter: function updateSelectedCapabilityFilter(state, filter) {
				state.selectedCapabilityFilter = filter;
			},
			updateSelectedLocationFilter: function updateSelectedLocationFilter(state, filter) {
				state.selectedLocationFilter = filter;
			},
			updateSelectedTopicFilter: function updateSelectedTopicFilter(state, filter) {
				state.selectedTopicFilter = filter;
			},
			updateSelectedTypeFilter: function updateSelectedTypeFilter(state, filter) {
				state.selectedTypeFilter = filter;
			},
			updateSelectedDateFilter: function updateSelectedDateFilter(state, filter) {
				state.selectedDateFilter = filter;
			},
			updateSelectedProfessionalFilter: function updateSelectedProfessionalFilter(state, filter) {
				state.selectedProfessionalFilter = filter;
			},
			setState: function setState(state, newState) {
				for (var key in newState) {
					state[key] = newState[key];
				}
			},
			loadMoreUpcoming: function loadMoreUpcoming(state) {
				state.upcomingPage++;
			},
			loadMore: function loadMore(state, _loadMore) {
				state.page++;
			},
			updateScroll: function updateScroll(state, scrollPosition) {
				state.scrollPosition = scrollPosition;
			},
			clearFilter: function clearFilter(state, filterName) {
				if (filterName === "capability") {
					state.selectedCapabilityFilter = utilities.defaultValues.selectedCapabilityFilter;
					utilities.clearDropdownSelection('field-capability');
				} else if (filterName === "location") {
					state.selectedLocationFilter = utilities.defaultValues.selectedLocationFilter;
					utilities.clearDropdownSelection('field-location');
				} else if (filterName === 'type') {
					state.selectedTypeFilter = utilities.defaultValues.selectedTypeFilter;
					utilities.clearDropdownSelection('field-type');
				} else if (filterName === "keyword") {
					state.searchKeyword = utilities.defaultValues.searchKeyword;
				} else if (filterName === 'professional') {
					state.selectedProfessionalFilter = utilities.defaultValues.selectedProfessionalFilter;
				} else if (filterName === 'time') {
					state.selectedDateFilter = utilities.defaultValues.selectedDateFilter;
					utilities.clearDropdownSelection('field-time-period');
				} else if (filterName === 'eventdate') {
					state.selectedEventDateFilter = utilities.defaultValues.selectedEventDateFilter;
					utilities.clearDropdownSelection('field-time-period');
				}
				$('#search-landing-filters').focus();
			},
			setSearching: function setSearching(state, searching) {
				state.searching = searching;
			},
			updateResults: function updateResults(state, results) {
				state.results = results;
			},
			updateResultCount: function updateResultCount(state, count) {
				state.resultCount = count;
			},
			updateUpcomingResultCount: function updateUpcomingResultCount(state, count) {
				state.upcomingResultCount = count;
			},
			updateUpcomingResults: function updateUpcomingResults(state, results) {
				state.upcomingResults = results;
			},
			updateSearchKeyword: function updateSearchKeyword(state, text) {
				state.searchKeyword = text;
			}
		},

		actions: {
			setSelectedEventDateFilter: function setSelectedEventDateFilter(context, date) {
				context.commit('updateSelectedEventDateFilter', date);
			},
			setCapabilityFilter: function setCapabilityFilter(context, filter) {
				context.commit("updateSelectedCapabilityFilter", filter);
				return context.dispatch('search');
			},
			setLocationFilter: function setLocationFilter(context, filter) {
				context.commit("updateSelectedLocationFilter", filter);
				return context.dispatch('search');
			},
			setTopicFilter: function setTopicFilter(context, filter) {
				context.commit("updateSelectedTopicFilter", filter);
				return context.dispatch('search');
			},
			setTypeFilter: function setTypeFilter(context, filter) {
				context.commit("updateSelectedTypeFilter", filter);
				return context.dispatch('search');
			},
			setDateFilter: function setDateFilter(context, filter) {
				context.commit("updateSelectedDateFilter", filter);
				return context.dispatch('search');
			},
			setState: function setState(context, newState) {
				context.commit('setState', newState);
			},
			loadMoreUpcoming: function loadMoreUpcoming(context) {
				context.commit('loadMoreUpcoming');
				return context.dispatch('search', { loadMore: true });
			},
			loadMore: function loadMore(context) {
				context.commit('loadMore');
				return context.dispatch('search', { loadMore: true });
			},
			updateScroll: function updateScroll(context, scrollPosition) {
				context.commit('updateScroll', scrollPosition);
			},
			clearFilter: function clearFilter(context, filterName) {

				var setFilters = [];
				if (context.state.selectedCapabilityFilter.FilterId !== '') {
					setFilters.push(context.state.selectedCapabilityFilter);
				}
				if (context.state.selectedLocationFilter.FilterId !== '') {
					setFilters.push(context.state.selectedLocationFilter);
				}
				if (context.state.searchKeyword !== '') {
					setFilters.push(context.state.searchKeyword);
				}
				if (context.state.selectedTopicFilter.FilterId !== '') {
					setFilters.push(context.state.selectedTopicFilter);
				}
				if (context.state.selectedTypeFilter.FilterId !== '') {
					setFilters.push(context.state.selectedTypeFilter);
				}
				if (context.state.selectedProfessionalFilter.FilterId !== '') {
					setFilters.push(context.state.selectedProfessionalFilter);
				}
				if (context.state.selectedDateFilter.FilterId !== '') {
					setFilters.push(context.state.selectedDateFilter);
				}
				if (context.state.selectedEventDateFilter !== '') {
					setFilters.push(context.state.selectedEventDateFilter);
				}
				$('#search-landing-filters').focus();

				if (setFilters.length > 1) {
					context.commit('clearFilter', filterName);
					return context.dispatch("search", { clearedFilterName: filterName });
				} else {
					//reset state
					context.commit('resetState');
				}
			},
			clearAllFilters: function clearAllFilters(context) {
				context.commit('resetState');
				window.history.pushState(utilities.defaultValues, '', window.location.pathname);
			},
			performKeywordSearch: function performKeywordSearch(context, term) {
				context.commit('updateSearchKeyword', term);
				return context.dispatch('search');
			},
			performProfessionalSearch: function performProfessionalSearch(context, professional) {
				var lawyerFilter = {};
				lawyerFilter.Name = professional.name;
				lawyerFilter.FilterId = professional.id;
				context.commit('updateSelectedProfessionalFilter', lawyerFilter);
				return context.dispatch('search');
			},
			updateSearchKeyword: function updateSearchKeyword(context, value) {
				context.commit('updateSearchKeyword', value);
			},
			updateSearchProfessional: function updateSearchProfessional(context, value) {
				context.commit('updateSearchProfessional', value);
			},
			updateSearchProfessionalId: function updateSearchProfessionalId(context, value) {
				context.commit('updateSearchProfessionalId', value);
			},
			search: function search(context, payload) {
				payload = payload || {};
				var clearedFilterName = payload.clearedFilterName;
				var loadMore = payload.loadMore;

				if (!loadMore) {
					context.commit('setState', { page: 1, upcomingPage: 1 });
				}

				var dateFilterId = '';
				if (context.state.selectedDateFilter) {
					dateFilterId = context.state.selectedDateFilter.FilterId;
				}

				return utilities.search(landingSettingsDefaultValues.searchApiUrl, context.state.page, context.state.take, context.state.upcomingPage, context.state.upcomingTake, context.state.searchKeyword, context.state.selectedEventDateFilter, dateFilterId, context.state.selectedLocationFilter.FilterId, context.state.selectedCapabilityFilter.FilterId, context.state.selectedTopicFilter.FilterId, context.state.selectedTypeFilter.FilterId, context.state.selectedProfessionalFilter.FilterId).then(function (searchResult) {
					// if we are not on first page, then add to results
					var firstResultIndex = 0;
					if (context.state.page != 1) {
						firstResultIndex = context.state.results.length;
					}

					if (loadMore) {
						var paginatedResults = context.state.results;
						paginatedResults = paginatedResults.concat(searchResult.Results);
						context.commit('updateResults', paginatedResults);
					} else {
						context.commit('updateResults', searchResult.Results);
						context.commit('updateResultCount', searchResult.ResultCount);
					}

					if (landingSettingsDefaultValues.templateName === 'events') {
						if (context.state.upcomingPage != 1) {
							firstResultIndex = context.state.upcomingResults.length;
						}

						if (loadMore) {
							var paginatedUpcomingResults = context.state.upcomingResults;
							paginatedUpcomingResults = paginatedUpcomingResults.concat(searchResult.UpcomingResults);
							context.commit('updateUpcomingResults', paginatedUpcomingResults);
						} else {
							context.commit('updateUpcomingResultCount', searchResult.UpcomingResultCount);
							context.commit('updateUpcomingResults', searchResult.UpcomingResults);
						}
					}

					var newUrl = '';

					if (context.state.page != 0) {
						newUrl = utilities.setUrlParameter("page", context.state.page, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (landingSettingsDefaultValues.templateName === 'events' && context.state.upcomingPage != 0) {
						newUrl = utilities.setUrlParameter("upcomingpage", context.state.upcomingPage, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.searchKeyword !== "") {
						newUrl = utilities.setUrlParameter("keyword", context.state.searchKeyword, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedEventDateFilter !== "") {
						newUrl = utilities.setUrlParameter("eventdate", context.state.selectedEventDateFilter, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedProfessionalFilter.FilterId != '' || clearedFilterName === "professional") {
						newUrl = utilities.setUrlParameter('professional', context.state.selectedProfessionalFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedCapabilityFilter.FilterId !== "" || clearedFilterName === "capability") {
						newUrl = utilities.setUrlParameter("capability", context.state.selectedCapabilityFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedLocationFilter.FilterId !== "" || clearedFilterName === "location") {
						newUrl = utilities.setUrlParameter("location", context.state.selectedLocationFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedTopicFilter.FilterId !== "" || clearedFilterName === "topic") {
						newUrl = utilities.setUrlParameter("topic", context.state.selectedTopicFilter.FilterId, newUrl !== '' ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedTypeFilter.FilterId !== "" || clearedFilterName === "type") {
						newUrl = utilities.setUrlParameter("type", context.state.selectedTypeFilter.FilterId, newUrl !== '' ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.selectedDateFilter.FilterId !== "" || clearedFilterName === "date") {
						newUrl = utilities.setUrlParameter("date", context.state.selectedDateFilter.FilterId, newUrl !== '' ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (context.state.scrollPosition !== 0) {
						newUrl = utilities.setUrlParameter("scroll", context.state.scrollPosition, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
					}

					if (newUrl !== "") {
						context.commit('setSearching', true);
						window.history.pushState(context.state, '', newUrl);
					}

					return firstResultIndex;
				});
			}

		}

	});

	var articleSearchApp = new Vue({

		el: '#articles-landing',

		store: store,

		data: {
			currentSearchKeyword: '',
			currentSearchProfessional: '',
			showMobileFilters: false,
			eventPage: true,
			focusedDropdowns: []
		},

		created: function created() {
			window.onpopstate = this.onPopState;

			if (this.$store.getters.scrollPosition !== 0) {
				utilities.scrollTo(this.$store.getters.scrollPosition);
			}
		},

		computed: {
			upcomingResultCount: function upcomingResultCount() {
				return this.$store.getters.upcomingResultCount;
			},
			upcomingResults: function upcomingResults() {
				return this.$store.getters.upcomingResults;
			},
			results: function results() {
				return this.$store.getters.results;
			},
			resultCount: function resultCount() {
				return this.$store.getters.resultCount;
			},
			searching: function searching() {
				return this.$store.getters.searching;
			},
			upcomingTake: function upcomingTake() {
				return this.$store.getters.upcomingTake;
			},
			upcomingPage: function upcomingPage() {
				return this.$store.getters.upcomingPage;
			},
			page: function page() {
				return this.$store.getters.page;
			},
			take: function take() {
				return this.$store.getters.take;
			},
			searchKeyword: function searchKeyword() {
				return this.$store.getters.searchKeyword;
			},
			scrollPosition: function scrollPosition() {
				return this.$store.getters.scrollPosition;
			},
			capabilityFilters: function capabilityFilters() {
				return this.$store.getters.capabilityFilters;
			},
			locationFilters: function locationFilters() {
				return this.$store.getters.locationFilters;
			},
			topicFilters: function topicFilters() {
				return this.$store.getters.topicFilters;
			},
			typeFilters: function typeFilters() {
				return this.$store.getters.typeFilters;
			},
			dateFilters: function dateFilters() {
				return this.$store.getters.dateFilters;
			},
			selectedCapabilityFilter: function selectedCapabilityFilter() {
				return this.$store.getters.selectedCapabilityFilter;
			},
			selectedLocationFilter: function selectedLocationFilter() {
				return this.$store.getters.selectedLocationFilter;
			},
			selectedTopicFilter: function selectedTopicFilter() {
				return this.$store.getters.selectedTopicFilter;
			},
			selectedTypeFilter: function selectedTypeFilter() {
				return this.$store.getters.selectedTypeFilter;
			},
			selectedDateFilter: function selectedDateFilter() {
				return this.$store.getters.selectedDateFilter;
			},
			selectedProfessionalFilter: function selectedProfessionalFilter() {
				return this.$store.getters.selectedProfessionalFilter;
			},
			selectedEventDateFilter: function selectedEventDateFilter() {
				return this.$store.getters.selectedEventDateFilter;
			},
			filterCount: function filterCount() {
				var setFilters = [];

				if (this.selectedCapabilityFilter.FilterId !== '') {
					setFilters.push(this.selectedCapabilityFilter);
				}
				if (this.selectedLocationFilter.FilterId !== '') {
					setFilters.push(this.selectedLocationFilter);
				}
				if (this.selectedTopicFilter.FilterId !== '') {
					setFilters.push(this.selectedTopicFilter);
				}
				if (this.selectedTypeFilter.FilterId !== '') {
					setFilters.push(this.selectedTypeFilter);
				}
				if (this.selectedDateFilter.FilterId !== '') {
					setFilters.push(this.selectedDateFilter);
				}
				if (this.selectedProfessionalFilter.FilterId !== '') {
					setFilters.push(this.searchProfessional);
				}
				if (this.selectedEventDateFilter !== '') {
					setFilters.push(this.selectedEventDateFilter);
				}
				if (this.searchKeyword !== '') {
					setFilters.push(this.searchKeyword);
				}

				return setFilters.length;
			}
		},

		methods: {
			toggleFilters: function toggleFilters() {
				this.showMobileFilters = !this.showMobileFilters;
			},
			shouldShowFilter: function shouldShowFilter(filterName) {
				var currentTemplateName = landingSettingsDefaultValues.templateName;
				if (currentTemplateName === 'perspectives') {
					return utilities.perspectiveFilters.indexOf(filterName) !== -1;
				} else if (currentTemplateName === 'news') {
					return utilities.newsFilters.indexOf(filterName) !== -1;
				} else if (currentTemplateName === 'events') {
					return utilities.eventFilters.indexOf(filterName) !== -1;
				}
			},
			capabilityFilterChange: function capabilityFilterChange(value) {
				if (value) {
					var filter = JSON.parse(value);
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
					this.$store.dispatch('setCapabilityFilter', filter);
				} else {
					this.$store.dispatch('clearFilter', 'capability');
				}
			},
			locationFilterChange: function locationFilterChange(value) {
				if (value) {
					var filter = JSON.parse(value);
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
					this.$store.dispatch('setLocationFilter', filter);
				} else {
					this.$store.dispatch('clearFilter', 'location');
				}
			},
			topicFilterChange: function topicFilterChange(value) {
				if (value) {
					var filter = JSON.parse(value);
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
					this.$store.dispatch('setTopicFilter', filter);
				} else {
					this.$store.dispatch('clearFilter', 'topic');
				}
			},
			typeFilterChange: function typeFilterChange(value) {
				if (value) {
					var filter = JSON.parse(value);
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
					this.$store.dispatch('setTypeFilter', filter);
				} else {
					this.$store.dispatch('clearFilter', 'type');
				}
			},
			dateFilterChange: function dateFilterChange(value) {
				if (value) {
					var filter = JSON.parse(value);
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
					this.$store.dispatch('setDateFilter', filter);
				} else {
					this.$store.dispatch('clearFilter', 'time');
				}
			},
			loadMoreUpcoming: function loadMoreUpcoming() {
				//this.$store.dispatch('updateScroll', utilities.getUpcomingLoadMoreLocation())
				this.$store.dispatch('loadMoreUpcoming').then(function (firstResultIndex) {
					$('#search-entity-landing-results').find('.listing-articles-title').get(firstResultIndex).focus();
				});
			},
			loadMore: function loadMore() {
				//this.$store.dispatch('updateScroll', utilities.getLoadMoreLocation())
				this.$store.dispatch('loadMore').then(function (firstResultIndex) {
					$('#search-entity-landing-results').find('.listing-articles-title').get(firstResultIndex).focus();
				});
			},
			searchButtonClick: function searchButtonClick() {
				var datePicker = document.getElementById('datepicker');
				var eventStart = '';

				if (datePicker) {
					var eventStart = datePicker.value;
				}

				if (this.currentSearchKeyword !== '' || eventStart !== '') {

					if (this.currentSearchKeyword != '') {
						this.$store.dispatch('updateSearchKeyword', this.currentSearchKeyword);
						// this.currentSearchKeyword = ''
					}

					if (eventStart !== '') {
						datePicker.value = '';
						this.$store.dispatch('setSelectedEventDateFilter', eventStart);
					}

					this.$store.dispatch('search');
					// var scrollValue = utilities.defaultScroll()
					// this.$store.dispatch('updateScroll', scrollValue)
				}
			},
			clearFilter: function clearFilter(filterName) {
				if (filterName == 'keyword') {
					this.currentSearchKeyword = '';
				}
				this.$store.dispatch('clearFilter', filterName);
				window.history.pushState(utilities.defaultValues, '', window.location.pathname);
			},
			clearAllFilters: function clearAllFilters() {
				this.$store.dispatch('clearAllFilters');
				$('#search-landing-filters').focus();
				window.history.pushState(utilities.defaultValues, '', window.location.pathname);
			},
			onPopState: function onPopState() {
				if (window.history.state) {
					this.$store.dispatch('setState', window.history.state);
					if (this.scrollPosition !== 0) {
						utilities.scrollTo(this.scrollPosition);
					}
				} else {
					this.$store.dispatch('clearFilter');
				}
			},
			getLinkTarget: function getLinkTarget(isExternalLink) {
				return isExternalLink ? "_blank" : "_self";
			},
			focusFilter: function focusFilter(filterName) {
				if (!this.focusedDropdowns.indexOf(filterName) > -1) {
					this.focusedDropdowns.push(filterName);
				}
			},
			blurFilter: function blurFilter(filterName) {
				if (this.focusedDropdowns.indexOf(filterName) > -1) {
					var index = this.focusedDropdowns.indexOf(filterName);
					if (index > -1) {
						this.focusedDropdowns.splice(index, 1);
					}
				}
			}
		},
		components: {
			'search-typeahead': {
				template: '#search-typeahead',
				extends: VueTypeahead.default,
				props: {
					placeholdertext: String,
					arialabel: {
						type: String,
						default: ''
					},
					defaultstatus: String,
					populatedstatus: String,
					emptystatus: String
				},
				data: function data() {
					return {
						src: landingSettingsDefaultValues.typeAheadApiUrl,
						limit: 5,
						minChars: 3,
						queryParamName: 'professional',
						lastQuery: '',
						lastCurrent: -1
					};
				},
				computed: {
					status: function status() {
						if (this.query.length >= this.minChars && !this.loading) {
							if (this.hasItems) {
								return this.populatedstatus.replace(/\{0\}/g, this.items.length);
							} else {
								return this.emptystatus;
							}
						} else {
							return this.defaultstatus.replace(/\{0\}/g, this.minChars);
						}
					},
					currentTitle: function currentTitle() {
						var currentItem = this.items[this.current];
						return currentItem ? currentItem.Title : '';
					}
				},
				methods: {
					onHit: function onHit(item) {
						this.query = item.Title;
						this.resetCustom();
						this.$store.dispatch('performProfessionalSearch', { name: item.Title, id: item.Id });
						this.query = '';
					},
					beforeNav: function beforeNav() {
						this.lastCurrent = this.current;
						if (this.current === -1) {
							this.lastQuery = this.query;
						}
					},
					afterNav: function afterNav() {
						if (this.current !== -1) {
							this.query = this.currentTitle;
						} else {
							this.query = this.lastQuery;
						}
					},
					updateCustom: function updateCustom() {
						if (this.current === -1) {
							this.update();
						}
					},
					upCustom: function upCustom() {
						this.beforeNav();
						this.up();
						this.afterNav();
					},
					downCustom: function downCustom() {
						this.beforeNav();
						this.down();
						this.afterNav();
					},
					resetCustom: function resetCustom() {
						this.reset();
						this.current = -1;
						this.lastCurrent = -1;
						this.lastQuery = '';
					},
					clearTypeahead: function clearTypeahead() {
						this.items = [];
						this.current = -1;
						this.lastCurrent = -1;
						this.lastQuery = '';
					}
				}
			},
			'recent-articles': {
				template: '#recent-articles',
				props: [],
				data: function data() {
					return {
						recentArticles: landingSettingsDefaultValues.recentArticles,
						articleCount: landingSettingsDefaultValues.recentArticleCount,
						pageSize: landingSettingsDefaultValues.recentArticlesPageSize,
						recentArticlesApiUrl: landingSettingsDefaultValues.recentArticlesApiUrl,
						page: landingSettingsDefaultValues.recentArticlesPage,
						loadMoreText: landingSettingsDefaultValues.loadMoreText,
						headerText: landingSettingsDefaultValues.headerText,
						firstResultIndex: 0
					};
				},
				computed: {
					shownArticleCount: function shownArticleCount() {
						return this.page * this.pageSize;
					}
				},
				methods: {
					search: function search() {
						var self = this;
						recentArticlesUtility.search(this.recentArticlesApiUrl, this.pageSize, this.page).then(function (result) {
							if (self.page != 0) {
								var firstResultIndex = self.recentArticles.length;
								var paginatedArticles = self.recentArticles;
								paginatedArticles = paginatedArticles.concat(result.Results);
								self.recentArticles = paginatedArticles;
								setTimeout(function () {
									console.log('self.recentArticles.length', self.recentArticles.length);
									$('#search-entity-landing-promo').find('.listing-articles-title').get(firstResultIndex).focus();
								}, 200);
							} else {
								self.recentArticles = result.Results;
							}
						});
					},
					loadMore: function loadMore() {
						this.page++;
						this.search();
					},
					getLinkTarget: function getLinkTarget(isExternalLink) {
						return isExternalLink ? "_blank" : "_self";
					}
				}
			}
		}
	});
}

},{}],16:[function(require,module,exports){
'use strict';

if (document.getElementById('capabilities-search')) {
  var utilities = {
    defaultElement: '#search-landing-filters',
    loadMoreElement: '#load-more-button',
    headerMenuElement: '#site-header',
    defaultResults: {
      ResultsCount: 0,
      Results: []
    },
    emptyValues: {
      results: [],
      resultCount: 0,
      searching: false,
      page: 0,
      take: 25,
      searchKeyword: "",
      scrollPosition: 0
    },
    defaultValues: {
      results: serverJson.InitialResults.Results || [],
      resultCount: serverJson.InitialResults.ResultCount || 0,
      searching: serverJson.Searching || false,
      page: serverJson.Page || 0,
      take: serverJson.Take || 25,
      searchKeyword: serverJson.SearchKeyword || "",
      scrollPosition: serverJson.ScrollPosition || 0,
      parentServices: serverParents || []
    },
    getLoadMoreLocation: function getLoadMoreLocation() {
      return parseInt($(this.loadMoreElement).offset().top);
    },
    getParams: function getParams(url) {
      var searchParams = url;

      if (searchParams.length == 0) {
        return [];
      }

      searchParams = searchParams.substr(1);

      var params = searchParams.split('&');

      var objects = params.map(function (equation) {
        var sides = equation.split('=');
        return {
          key: sides[0],
          value: sides[1]
        };
      });

      return objects;
    },
    getNewUrlParams: function getNewUrlParams(paramsList) {
      var paramPairs = paramsList.map(function (param) {
        return param.key + '=' + param.value;
      });

      var newUrlParams = '?' + paramPairs.join('&');

      return window.location.pathname + newUrlParams;
    },
    setUrlParameter: function setUrlParameter(key, value, url) {
      var params = this.getParams(url);

      var paramsWithKey = params.filter(function (param) {
        return param.key === key;
      });

      var paramsWithoutKey = params.filter(function (param) {
        return param.key !== key;
      });

      var paramWithKey = paramsWithKey[0];

      var paramExists = paramWithKey !== undefined;

      if (paramExists) {
        paramWithKey.value = value;
      } else {
        paramWithKey = {
          key: key,
          value: value
        };
      }

      var newParams = paramsWithoutKey;

      if (value != undefined) newParams.push(paramWithKey);

      return this.getNewUrlParams(newParams);
    },
    defaultScroll: function defaultScroll() {
      var offset = parseInt($(window).height() / 2);

      var location = parseInt($(this.defaultElement).offset().top);
      $("html, body").animate({
        scrollTop: location - offset
      }, "slow", function () {
        // var $resultCount = $('#search-top');
        // $resultCount
        //   .focus()
        //.attr('tabindex', -1);
      });

      return location - offset;
    },
    scrollTo: function scrollTo(location) {
      $("html, body").animate({
        scrollTop: location
      }, "slow", function () {
        // var $resultCount = $('#search-top');
        // $resultCount
        //   .focus()
      });
    },
    search: function search(keyword, take, page) {
      return Vue.http.post('/api/capabilities/search', {
        Keyword: keyword,
        Take: take,
        Skip: (page - 1) * take
      }).then(function (response) {

        if (response.status == 200) {
          return response.body;
        } else {
          console.log("error while retrieving search results");
          return defaultResults;
        }
      }, function (error) {
        return defaultResults;
      });
    }
  };

  Vue.use(VueResource);

  var store = new Vuex.Store({

    state: {
      results: utilities.defaultValues.results,
      resultCount: utilities.defaultValues.resultCount,
      searching: utilities.defaultValues.searching,
      page: utilities.defaultValues.page,
      take: utilities.defaultValues.take,
      searchKeyword: utilities.defaultValues.searchKeyword,
      scrollPosition: utilities.defaultValues.scrollPosition
    },

    getters: {

      results: function results(state) {
        return state.results;
      },
      resultCount: function resultCount(state) {
        return state.resultCount;
      },
      searching: function searching(state) {
        return state.searching;
      },
      page: function page(state) {
        return state.page;
      },
      take: function take(state) {
        return state.take;
      },
      searchKeyword: function searchKeyword(state) {
        return state.searchKeyword;
      },
      scrollPosition: function scrollPosition(state) {
        return state.scrollPosition;
      }

    },

    mutations: {
      setState: function setState(state, newState) {
        for (var key in newState) {
          state[key] = newState[key];
        }
      },
      loadMore: function loadMore(state, _loadMore) {
        state.page++;
      },
      updateScroll: function updateScroll(state, scrollPosition) {
        state.scrollPosition = scrollPosition;
      },
      clearFilter: function clearFilter(state) {
        var defaults = utilities.emptyValues;
        for (var key in defaults) {
          state[key] = defaults[key];
        }
      },
      setSearching: function setSearching(state, searching) {
        state.searching = searching;
      },
      updateResults: function updateResults(state, results) {
        state.results = results;
      },
      updateResultCount: function updateResultCount(state, count) {
        state.resultCount = count;
      },
      updateSearchKeyword: function updateSearchKeyword(state, text) {
        state.searchKeyword = text;
      }
    },

    actions: {
      setState: function setState(context, newState) {
        context.commit('setState', newState);
      },
      loadMore: function loadMore(context) {
        context.commit('loadMore');
        return context.dispatch('search', { loadMore: true });
      },
      updateScroll: function updateScroll(context, scrollPosition) {
        context.commit('updateScroll', scrollPosition);
      },
      clearFilter: function clearFilter(context) {
        context.commit('clearFilter');
        $('#search-landing-filters').focus();
      },
      performKeywordSearch: function performKeywordSearch(context, term) {
        context.commit('updateSearchKeyword', term);
        return context.dispatch('search');
      },
      search: function search(context, payload) {
        payload = payload || {};
        var loadMore = payload.loadMore;

        if (!loadMore) {
          context.commit('setState', { page: 1 });
        }

        return utilities.search(context.state.searchKeyword, context.state.take, context.state.page).then(function (result) {
          console.log('page:', context.state.page);
          console.log('current results:', context.state.results);
          console.log('new results:', result);

          // if we are not on first page, then add to results
          var firstResultIndex = 0;
          if (context.state.page != 0 && context.state.results.length > 0) {
            firstResultIndex = context.state.results.length;
          }

          if (loadMore && context.state.results.length > 0) {
            var paginatedResults = context.state.results;
            paginatedResults = paginatedResults.concat(result.Results);
            context.commit('updateResults', paginatedResults);
            context.commit('updateResultCount', result.ResultCount);
          } else {
            context.commit('updateResults', result.Results);
            context.commit('updateResultCount', result.ResultCount);
          }

          var newUrl = '';

          if (context.state.page != 0) {
            newUrl = utilities.setUrlParameter("page", context.state.page, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.searchKeyword !== "") {
            newUrl = utilities.setUrlParameter("keyword", context.state.searchKeyword, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.scrollPosition !== 0) {
            newUrl = utilities.setUrlParameter("scroll", context.state.scrollPosition, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (newUrl !== "") {
            context.commit('setSearching', true);
            window.history.pushState(context.state, '', newUrl);
          }

          return firstResultIndex;
        });
      }

    }

  });

  var capabilitySearchApp = new Vue({
    el: '#capabilities-search',
    store: store,
    data: {
      currentSearchKeyword: '',
      toggledPractices: {},
      parentServices: utilities.defaultValues.parentServices,
      expanded: true,
      expandObj: { expanded: true },
      allExpanded: false,
      viewAll: false
    },
    created: function created() {
      window.onpopstate = this.onPopState;

      if (this.$store.getters.scrollPosition !== 0) {
        utilities.scrollTo(this.$store.getters.scrollPosition);
      }
    },
    computed: {
      results: function results() {
        return this.$store.getters.results;
      },
      resultCount: function resultCount() {
        return this.$store.getters.resultCount;
      },
      searching: function searching() {
        return this.$store.getters.searching;
      },
      page: function page() {
        return this.$store.getters.page;
      },
      take: function take() {
        return this.$store.getters.take;
      },
      searchKeyword: function searchKeyword() {
        return this.$store.getters.searchKeyword;
      },
      scrollPosition: function scrollPosition() {
        return this.$store.getters.scrollPosition;
      }

    },
    methods: {
      toggleCapabilityView: function toggleCapabilityView() {
        this.viewAll = !this.viewAll;
      },
      isExpandedPractice: function isExpandedPractice(key) {
        var toggleStatus = this.toggledPractices[key];
        if (toggleStatus) {
          return toggleStatus;
        }
        return false;
      },
      togglePractice: function togglePractice(name) {
        var updatedToggledPractices = JSON.parse(JSON.stringify(this.toggledPractices));
        var toggleStatus = updatedToggledPractices[name];
        if (toggleStatus !== undefined) {
          updatedToggledPractices[name] = !toggleStatus;
        } else {
          updatedToggledPractices[name] = true;
        }
        this.toggledPractices = updatedToggledPractices;
      },
      toggleAllServices: function toggleAllServices() {
        this.allExpanded = !this.allExpanded;
        var updatedToggledServices = JSON.parse(JSON.stringify(this.toggledPractices));

        if (this.parentServices && this.parentServices.length > 0) {
          for (i = 0; i < this.parentServices.length; i++) {
            updatedToggledServices[this.parentServices[i]] = this.allExpanded;
          }

          this.toggledPractices = updatedToggledServices;
        }
      },
      loadMore: function loadMore() {
        this.$store.dispatch('updateScroll', utilities.getLoadMoreLocation());
        this.$store.dispatch("loadMore").then(function (firstResultIndex) {
          $('#capabilities-search').find('.result a').get(firstResultIndex).focus();
        });
      },
      searchButtonClick: function searchButtonClick() {
        if (this.currentSearchKeyword !== "") {
          //utilities.defaultScroll()
          this.$store.dispatch('performKeywordSearch', this.currentSearchKeyword);
        }
        this.currentSearchKeyword = "";
      },
      clearFilter: function clearFilter() {
        this.$store.dispatch('clearFilter');
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },
      onPopState: function onPopState() {
        if (window.history.state) {
          this.$store.dispatch('setState', window.history.state);
          if (this.scrollPosition !== 0) {
            utilities.scrollTo(this.scrollPosition);
          }
        } else {
          this.$store.dispatch('clearFilter');
        }
      },
      scrollToSection: function scrollToSection(element) {
        var scrollToElement = $('#' + element);
        if (scrollToElement) {
          this.scrollLocation = parseInt(scrollToElement.offset().top - $(utilities.headerMenuElement).height());
          utilities.scrollTo(this.scrollLocation);
          scrollToElement.focus();
          var url = window.location.protocol + "//" + window.location.host + window.location.pathname + '?scroll=' + this.scrollLocation;
          window.history.pushState({ path: url }, '', url);
        }
      }
    },
    components: {
      'search-typeahead': {
        template: '#search-typeahead-capabilities',
        extends: VueTypeahead.default,
        props: {
          placeholdertext: String,
          arialabel: {
            type: String,
            default: ''
          },
          defaultstatus: String,
          populatedstatus: String,
          emptystatus: String,
          buttonlabel: String
        },
        data: function data() {
          return {
            src: '/api/capabilities/typeaheadservice',
            limit: 6,
            minChars: 3,
            queryParamName: 'name',
            lastQuery: '',
            lastCurrent: -1
          };
        },
        computed: {
          status: function status() {
            if (this.query.length >= this.minChars && !this.loading) {
              if (this.hasItems) {
                return this.populatedstatus.replace(/\{0\}/g, this.items.length);
              } else {
                return this.emptystatus;
              }
            } else {
              return this.defaultstatus.replace(/\{0\}/g, this.minChars);
            }
          },
          currentTitle: function currentTitle() {
            var currentItem = this.items[this.current];
            return currentItem ? currentItem.Title : '';
          }
        },
        methods: {
          onHit: function onHit(item) {
            window.location.href = item.Url;
          },
          search: function search() {
            if (this.current >= 0 && this.hasItems) {
              var selectedIndex = this.current;
              window.location.href = this.items[selectedIndex].Url;
            } else {
              if (this.query !== "") {
                //utilities.defaultScroll()
                this.$store.dispatch('performKeywordSearch', this.query);
              }
            }
            this.reset();
            this.query = '';
          },
          beforeNav: function beforeNav() {
            this.lastCurrent = this.current;
            if (this.current === -1) {
              this.lastQuery = this.query;
            }
          },
          afterNav: function afterNav() {
            if (this.current !== -1) {
              this.query = this.currentTitle;
            } else {
              this.query = this.lastQuery;
            }
          },
          updateCustom: function updateCustom() {
            if (this.current === -1) {
              this.update();
            }
          },
          upCustom: function upCustom() {
            this.beforeNav();
            this.up();
            this.afterNav();
          },
          downCustom: function downCustom() {
            this.beforeNav();
            this.down();
            this.afterNav();
          },
          resetCustom: function resetCustom() {
            this.reset();
            this.current = -1;
            this.lastCurrent = -1;
            this.lastQuery = '';
          },
          clearTypeahead: function clearTypeahead() {
            this.items = [];
            this.current = -1;
            this.lastCurrent = -1;
            this.lastQuery = '';
          }
        }
      }
    }
  });
}

},{}],17:[function(require,module,exports){
"use strict";

if (document.getElementById('careers-interview')) {
  var utilities = {
    createGetter: function createGetter(propertyName) {
      return function (state) {
        return state[propertyName];
      };
    },

    defaultScroll: function defaultScroll() {
      var offset = parseInt($(window).height() / 2);
      var location = parseInt($(this.defaultElement).offset().top);
      $("html, body").animate({
        scrollTop: location - offset
      }, "slow", function () {
        var $resultCount = $('.searched-criteria-results');
        $resultCount.focus().attr('tabindex', -1);
      });

      return location - offset;
    },

    scrollTo: function scrollTo(location) {
      $("html, body").animate({
        scrollTop: location
      }, "slow");
    },

    getParams: function getParams(url) {

      var searchParams = url;

      if (searchParams.length == 0) return [];

      var searchParamsOne = searchParams.substr(1);
      var searchParamsList = searchParamsOne.split('?');
      searchParams = searchParamsList[1] ? searchParamsList[1] : searchParamsOne;
      var params = searchParams.split('&');

      var objects = params.map(function (equation) {

        var sides = equation.split('=');

        return {
          key: sides[0],
          value: sides[1]
        };
      });

      return objects;
    },

    getNewUrlParams: function getNewUrlParams(paramsList) {

      var paramPairs = paramsList.map(function (param) {

        return param.key + '=' + param.value;
      });

      var newUrlParams = '?' + paramPairs.join('&');

      return window.location.pathname + newUrlParams;
    },

    setUrlParameter: function setUrlParameter(key, value, url) {
      var params = this.getParams(url);

      var paramsWithKey = params.filter(function (param) {
        return param.key === key;
      });

      var paramsWithoutKey = params.filter(function (param) {
        return param.key !== key;
      });

      var paramWithKey = paramsWithKey[0];

      var paramExists = paramWithKey !== undefined;

      if (paramExists) {

        // Update value for parameter
        paramWithKey.value = value;
      } else {

        paramWithKey = {
          key: key,
          value: value
        };
      }

      var newParams = paramsWithoutKey;

      // Won't add key if value is null/undefined
      if (value != undefined) newParams.push(paramWithKey);

      return this.getNewUrlParams(newParams);
    },

    emptyValues: {
      searching: false,
      selectedLocationFilter: "",
      filters: [],
      scrollLocation: 0,
      schools: [],
      results: []
    },

    defaultValues: {
      searching: serverJson.Searching || false,
      schools: serverJson.Schools || [],
      results: serverJson.SearchResults.Results || [],
      selectedLocationFilter: serverJson.SelectedLocationFilter || "",
      locationFilters: serverJson.LocationFilters || [],
      filters: [],
      scrollLocation: serverJson.Scroll || 0
    }
  };

  Vue.use(VueResource);

  var store = new Vuex.Store({

    state: {
      schools: utilities.defaultValues.schools,
      results: utilities.defaultValues.results,
      searching: utilities.defaultValues.searching,
      selectedLocationFilter: utilities.defaultValues.selectedLocationFilter,
      locationFilters: utilities.defaultValues.locationFilters,
      filters: utilities.defaultValues.filters,
      scrollLocation: utilities.defaultValues.scrollLocation
    },

    //For view / UI to render data from store.
    getters: {

      filtersSet: function filtersSet(state) {
        return state.selectedLocationFilter.FilterId !== "";
      },

      scrollLocation: utilities.createGetter('scrollLocation'),
      schools: utilities.createGetter('schools'),
      results: utilities.createGetter('results'),
      searching: utilities.createGetter('searching'),
      locationFilters: utilities.createGetter('locationFilters'),
      selectedLocationFilter: utilities.createGetter('selectedLocationFilter')
    },

    // For Actions to call and change / modify store.
    mutations: {
      updateScroll: function updateScroll(state, scrollLocation) {
        state.scrollLocation = scrollLocation;
      },

      updateSelectedLocationFilter: function updateSelectedLocationFilter(state, filter) {
        state.selectedLocationFilter = filter;
      },

      updateResults: function updateResults(state, results) {
        state.results = results;
      },

      updateSchools: function updateSchools(state, schools) {
        state.schools = schools;
      },

      setSearching: function setSearching(state, searching) {
        state.searching = searching;
      },

      setState: function setState(state, newState) {

        state.schools = newState.schools;
        state.results = newState.results;
        state.resultCount = newState.resultCount;
        state.searching = newState.searching;
        state.selectedLocationFilter = newState.selectedLocationFilter;
        state.filters = newState.filters;
        state.scrollLocation = newState.scrollLocation;
      },

      resetState: function resetState(state) {
        var defaults = utilities.emptyValues;
        for (var key in defaults) {
          state[key] = defaults[key];
        }
      },

      clearFilter: function clearFilter(state, filterName) {
        state.selectedLocationFilter = utilities.emptyValues.selectedLocationFilter;
        utilities.clearDropdownSelection('field-location');
      }
    },

    // For View / UI to call to call an action or mutation against store.
    actions: {
      updateScroll: function updateScroll(context, scrollLocation) {
        context.commit("updateScroll", scrollLocation);
      },

      setSearching: function setSearching(context, isSearching) {
        context.commit("setSearching", isSearching);
      },

      setLocationFilter: function setLocationFilter(context, filter) {
        context.commit("updateSelectedLocationFilter", filter);
        context.dispatch('search');
      },

      search: function search(context, clearedFilterName) {

        // Execute search via web service, then push parameters to url
        var result = context.state.schools.filter(function (school) {
          return school.Office === context.state.selectedLocationFilter;
        });
        context.commit("updateResults", result);

        var newUrl = "";

        if (context.state.selectedLocationFilter || clearedFilterName === "location") {
          newUrl = utilities.setUrlParameter("location", context.state.selectedLocationFilter, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
        }

        if (context.state.scrollLocation !== 0) {
          newUrl = utilities.setUrlParameter("scroll", context.state.scrollLocation, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
        }

        if (newUrl !== "") {
          // If any filter is enabled, display search mode.
          // Will hide search if final filter is cleared.
          var enableSearch = context.getters.filtersSet;
          context.commit("setSearching", enableSearch);
          window.history.pushState(context.state, '', newUrl);
        }
      },

      setState: function setState(context, newState) {
        context.commit('setState', newState);
      },

      resetState: function resetState(context) {
        context.commit('resetState');
      }

    }

  });

  var careersInterviewSearchApp = new Vue({

    el: '#careers-interview',

    store: store,

    created: function created() {

      window.onpopstate = this.onPopState;

      if (this.$store.getters.scrollLocation !== 0) {
        utilities.scrollTo(this.$store.getters.scrollLocation);
      } else if (this.$store.getters.searching) {
        //utilities.defaultScroll()
      }

      var params = utilities.getParams(window.location.href);

      if (params[0] && params[0].key === "location") {
        this.$store.dispatch('setLocationFilter', decodeURIComponent(params[0].value));
      }

      var app = this;
    },

    data: {
      focusedDropdowns: []
    },

    // Computed called in UI for displaying values from store, call store getters
    computed: Vuex.mapGetters(['searching', 'locationFilters', 'selectedLocationFilter', 'results', 'scrollLocation']),
    // Called in UI - dispatch store actions
    methods: {

      toggleFilters: function toggleFilters() {
        this.showMobileFilters = !this.showMobileFilters;
      },

      clearFilter: function clearFilter(filterName) {
        this.$store.dispatch('clearFilter', filterName);
      },

      clearAllFilters: function clearAllFilters() {
        this.$store.dispatch('resetState');
        utilities.clearDropdownSelection('field-location');
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },

      locationFilterChange: function locationFilterChange(value) {
        if (value == "") {
          this.clearFilter('location');
          return;
        }
        var filter = JSON.parse(value);
        //utilities.defaultScroll();
        this.$store.dispatch('setLocationFilter', filter);
      },

      onPopState: function onPopState() {

        if (window.history.state) {
          this.$store.dispatch('setState', window.history.state);

          if (this.$store.getters.scrollLocation !== 0) {
            utilities.scrollTo(this.$store.getters.scrollLocation);
          }
        } else {
          this.$store.dispatch('resetState');
        }
      },

      toBoolean: function toBoolean(status) {
        if (status == "false") return false;
        if (status == "true") return true;
        return true;
      },

      focusFilter: function focusFilter(filterName) {
        if (!this.focusedDropdowns.indexOf(filterName) > -1) {
          this.focusedDropdowns.push(filterName);
        }
      },

      blurFilter: function blurFilter(filterName) {
        if (this.focusedDropdowns.indexOf(filterName) > -1) {
          var index = this.focusedDropdowns.indexOf(filterName);
          if (index > -1) {
            this.focusedDropdowns.splice(index, 1);
          }
        }
      }
    }
  });
}

},{}],18:[function(require,module,exports){
'use strict';

if (document.getElementById('experience-landing')) {
  var utilities = {
    defaultElement: '#search-top',
    loadMoreElement: '#load-more-button',
    defaultResults: {
      ResultsCount: 0,
      Results: []
    },
    defaultValues: {
      results: serverJson.InitialResults.Results || [],
      resultCount: serverJson.InitialResults.ResultCount || 0,
      searching: serverJson.Searching || false,
      page: serverJson.Page || 0,
      take: serverJson.Take || 25,
      searchKeyword: serverJson.SearchKeyword || "",
      scrollPosition: serverJson.Scroll || 0,
      capabilityFilters: serverJson.CapabilityFilters,
      locationFilters: serverJson.OfficeFilters,
      regionFilters: serverJson.RegionFilters,
      selectedCapabilityFilter: serverJson.CapabilityFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "",
        FilterId: ""
      },
      selectedLocationFilter: serverJson.OfficeFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "",
        FilterId: ""
      },
      selectedRegionFilter: serverJson.RegionFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "",
        FilterId: ""
      }
    },
    getLoadMoreLocation: function getLoadMoreLocation() {
      return parseInt($(this.loadMoreElement).offset().top);
    },
    getParams: function getParams(url) {
      var searchParams = url;

      if (searchParams.length == 0) {
        return [];
      }

      searchParams = searchParams.substr(1);

      var params = searchParams.split('&');

      var objects = params.map(function (equation) {
        var sides = equation.split('=');
        return {
          key: sides[0],
          value: sides[1]
        };
      });

      return objects;
    },
    getNewUrlParams: function getNewUrlParams(paramsList) {
      var paramPairs = paramsList.map(function (param) {
        return param.key + '=' + param.value;
      });

      var newUrlParams = '?' + paramPairs.join('&');

      return window.location.pathname + newUrlParams;
    },
    setUrlParameter: function setUrlParameter(key, value, url) {
      var params = this.getParams(url);

      var paramsWithKey = params.filter(function (param) {
        return param.key === key;
      });

      var paramsWithoutKey = params.filter(function (param) {
        return param.key !== key;
      });

      var paramWithKey = paramsWithKey[0];

      var paramExists = paramWithKey !== undefined;

      if (paramExists) {
        paramWithKey.value = value;
      } else {
        paramWithKey = {
          key: key,
          value: value
        };
      }

      var newParams = paramsWithoutKey;

      if (value != undefined) newParams.push(paramWithKey);

      return this.getNewUrlParams(newParams);
    },
    defaultScroll: function defaultScroll() {
      var offset = parseInt($(window).height() / 2);

      var location = parseInt($(this.defaultElement).offset().top);
      $("html, body").animate({
        scrollTop: location - offset
      }, "slow", function () {
        var $resultCount = $('#search-top');
        $resultCount.focus();
      });

      return location - offset;
    },
    scrollTo: function scrollTo(location) {
      $("html, body").animate({
        scrollTop: location
      }, "slow", function () {
        var $resultCount = $('#search-top');
        $resultCount.focus();
      });
    },
    search: function search(keyword, capability, office, region, take, page) {
      return Vue.http.post('/api/capabilities/experiences', {
        Keyword: keyword,
        Take: take,
        Skip: (page - 1) * take,
        Capability: capability,
        Office: office,
        Region: region
      }).then(function (response) {

        if (response.status == 200) {
          return response.body;
        } else {
          console.log("error while retrieving search results");
          return defaultResults;
        }
      }, function (error) {
        return defaultResults;
      });
    }

  };

  Vue.use(VueResource);

  var store = new Vuex.Store({

    state: {
      results: utilities.defaultValues.results,
      resultCount: utilities.defaultValues.resultCount,
      searching: utilities.defaultValues.searching,
      page: utilities.defaultValues.page,
      take: utilities.defaultValues.take,
      searchKeyword: utilities.defaultValues.searchKeyword,
      scrollPosition: utilities.defaultValues.scrollPosition,
      capabilityFilters: utilities.defaultValues.capabilityFilters,
      locationFilters: utilities.defaultValues.locationFilters,
      regionFilters: utilities.defaultValues.regionFilters,
      selectedCapabilityFilter: utilities.defaultValues.selectedCapabilityFilter,
      selectedLocationFilter: utilities.defaultValues.selectedLocationFilter,
      selectedRegionFilter: utilities.defaultValues.selectedRegionFilter
    },

    getters: {
      capabilityFilters: function capabilityFilters(state) {
        return state.capabilityFilters;
      },
      locationFilters: function locationFilters(state) {
        return state.locationFilters;
      },
      regionFilters: function regionFilters(state) {
        return state.regionFilters;
      },
      selectedCapabilityFilter: function selectedCapabilityFilter(state) {
        return state.selectedCapabilityFilter;
      },
      selectedLocationFilter: function selectedLocationFilter(state) {
        return state.selectedLocationFilter;
      },
      selectedRegionFilter: function selectedRegionFilter(state) {
        return state.selectedRegionFilter;
      },
      results: function results(state) {
        return state.results;
      },
      resultCount: function resultCount(state) {
        return state.resultCount;
      },
      searching: function searching(state) {
        return state.searching;
      },
      page: function page(state) {
        return state.page;
      },
      take: function take(state) {
        return state.take;
      },
      searchKeyword: function searchKeyword(state) {
        return state.searchKeyword;
      },
      scrollPosition: function scrollPosition(state) {
        return state.scrollPosition;
      }

    },

    mutations: {
      resetState: function resetState(state) {
        var defaults = utilities.defaultValues;
        for (var key in defaults) {
          state[key] = defaults[key];
        }
      },
      updateSelectedCapabilityFilter: function updateSelectedCapabilityFilter(state, filter) {
        state.selectedCapabilityFilter = filter;
      },
      updateSelectedLocationFilter: function updateSelectedLocationFilter(state, filter) {
        state.selectedLocationFilter = filter;
      },
      updateSelectedRegionFilter: function updateSelectedRegionFilter(state, filter) {
        state.selectedRegionFilter = filter;
      },
      setState: function setState(state, newState) {
        for (var key in newState) {
          state[key] = newState[key];
        }
      },
      loadMore: function loadMore(state, _loadMore) {
        state.page++;
      },
      updateScroll: function updateScroll(state, scrollPosition) {
        state.scrollPosition = scrollPosition;
      },
      clearFilter: function clearFilter(state, filterName) {
        if (filterName === "capability") {
          state.selectedCapabilityFilter = utilities.defaultValues.selectedCapabilityFilter;
        } else if (filterName === "location") {
          state.selectedLocationFilter = utilities.defaultValues.selectedLocationFilter;
        } else if (filterName === "region") {
          state.selectedRegionFilter = utilities.defaultValues.selectedRegionFilter;
        } else if (filterName === "keyword") {
          state.searchKeyword = utilities.defaultValues.searchKeyword;
        }
        $('#search-landing-filters').focus();
      },
      setSearching: function setSearching(state, searching) {
        state.searching = searching;
      },
      updateResults: function updateResults(state, results) {
        state.results = results;
      },
      updateResultCount: function updateResultCount(state, count) {
        state.resultCount = count;
      },
      updateSearchKeyword: function updateSearchKeyword(state, text) {
        state.searchKeyword = text;
      }
    },

    actions: {
      setCapabilityFilter: function setCapabilityFilter(context, filter) {
        context.commit("updateSelectedCapabilityFilter", filter);
        return context.dispatch('search');
      },
      setLocationFilter: function setLocationFilter(context, filter) {
        context.commit("updateSelectedLocationFilter", filter);
        return context.dispatch('search');
      },
      setRegionFilter: function setRegionFilter(context, filter) {
        context.commit("updateSelectedRegionFilter", filter);
        return context.dispatch('search');
      },
      setState: function setState(context, newState) {
        context.commit('setState', newState);
      },
      loadMore: function loadMore(context) {
        context.commit('loadMore');
        return context.dispatch('search', { loadMore: true });
      },
      updateScroll: function updateScroll(context, scrollPosition) {
        context.commit('updateScroll', scrollPosition);
      },
      clearFilter: function clearFilter(context, filterName) {

        var setFilters = [];
        if (context.state.selectedCapabilityFilter.FilterId !== '') {
          setFilters.push(context.state.selectedCapabilityFilter);
        }
        if (context.state.selectedLocationFilter.FilterId !== '') {
          setFilters.push(context.state.selectedLocationFilter);
        }
        if (context.state.selectedRegionFilter.FilterId !== '') {
          setFilters.push(context.state.selectedRegionFilter);
        }
        if (context.state.searchKeyword !== '') {
          setFilters.push(context.state.searchKeyword);
        }

        if (setFilters.length > 1) {
          context.commit('clearFilter', filterName);
          return context.dispatch("search", { clearedFilterName: filterName });
        } else {
          //reset state
          context.commit('resetState');
        }
      },
      clearAllFilters: function clearAllFilters(context) {
        context.commit('resetState');
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },
      performKeywordSearch: function performKeywordSearch(context, term) {
        context.commit('updateSearchKeyword', term);
        return context.dispatch('search');
        //utilities.defaultScroll()
      },
      search: function search(context, payload) {
        payload = payload || {};
        var clearedFilterName = payload.clearedFilterName;
        var loadMore = payload.loadMore;
        var filters = [];

        if (!loadMore) {
          context.commit('setState', { page: 1 });
        }

        return utilities.search(context.state.searchKeyword, context.state.selectedCapabilityFilter.FilterId, context.state.selectedLocationFilter.FilterId, context.state.selectedRegionFilter.FilterId, context.state.take, context.state.page).then(function (result) {
          // if we are not on first page, then add to results
          var firstResultIndex = 0;
          if (context.state.page != 1) {
            firstResultIndex = context.state.results.length;
          }

          if (loadMore) {
            var paginatedResults = context.state.results;
            paginatedResults = paginatedResults.concat(result.Results);
            context.commit('updateResults', paginatedResults);
            context.commit('updateResultCount', result.ResultCount);
          } else {
            context.commit('updateResults', result.Results);
            context.commit('updateResultCount', result.ResultCount);
          }

          var newUrl = '';

          if (context.state.page != 0) {
            newUrl = utilities.setUrlParameter("page", context.state.page, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.searchKeyword !== "") {
            newUrl = utilities.setUrlParameter("keyword", context.state.searchKeyword, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedCapabilityFilter.FilterId !== "" || clearedFilterName === "capability") {
            newUrl = utilities.setUrlParameter("capability", context.state.selectedCapabilityFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedLocationFilter.FilterId !== "" || clearedFilterName === "location") {
            newUrl = utilities.setUrlParameter("location", context.state.selectedLocationFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedRegionFilter.FilterId !== "" || clearedFilterName === "admissions") {
            newUrl = utilities.setUrlParameter("region", context.state.selectedRegionFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.scrollPosition !== 0) {
            newUrl = utilities.setUrlParameter("scroll", context.state.scrollPosition, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (newUrl !== "") {
            context.commit('setSearching', true);
            window.history.pushState(context.state, '', newUrl);
          }

          return firstResultIndex;
        });
      }

    }

  });

  var capabilitySearchApp = new Vue({

    el: '#experience-landing',

    store: store,

    data: {
      currentSearchKeyword: '',
      showMobileFilters: false,
      focusedDropdowns: []
    },

    created: function created() {
      window.onpopstate = this.onPopState;

      if (this.$store.getters.scrollPosition !== 0) {
        utilities.scrollTo(this.$store.getters.scrollPosition);
      }
    },

    computed: {
      capabilityFilters: function capabilityFilters() {
        return this.$store.getters.capabilityFilters;
      },
      locationFilters: function locationFilters() {
        return this.$store.getters.locationFilters;
      },
      regionFilters: function regionFilters() {
        return this.$store.getters.regionFilters;
      },
      selectedCapabilityFilter: function selectedCapabilityFilter() {
        return this.$store.getters.selectedCapabilityFilter;
      },
      selectedLocationFilter: function selectedLocationFilter() {
        return this.$store.getters.selectedLocationFilter;
      },
      selectedRegionFilter: function selectedRegionFilter() {
        return this.$store.getters.selectedRegionFilter;
      },
      results: function results() {
        return this.$store.getters.results;
      },
      resultCount: function resultCount() {
        return this.$store.getters.resultCount;
      },
      searching: function searching() {
        return this.$store.getters.searching;
      },
      page: function page() {
        return this.$store.getters.page;
      },
      take: function take() {
        return this.$store.getters.take;
      },
      searchKeyword: function searchKeyword() {
        return this.$store.getters.searchKeyword;
      },
      scrollPosition: function scrollPosition() {
        return this.$store.getters.scrollPosition;
      },
      filterCount: function filterCount() {
        var setFilters = [];

        if (this.selectedCapabilityFilter.FilterId !== '') {
          setFilters.push(this.selectedCapabilityFilter);
        }
        if (this.selectedLocationFilter.FilterId !== '') {
          setFilters.push(this.selectedLocationFilter);
        }
        if (this.selectedRegionFilter.FilterId !== '') {
          setFilters.push(this.selectedRegionFilter);
        }
        if (this.searchKeyword !== '') {
          setFilters.push(this.searchKeyword);
        }

        return setFilters.length;
      }

    },

    methods: {
      toggleFilters: function toggleFilters() {
        this.showMobileFilters = !this.showMobileFilters;
      },
      capabilityFilterChange: function capabilityFilterChange(value) {
        if (value == "") {
          this.clearFilter('capability');
          return;
        }
        var filter = JSON.parse(value);
        //utilities.defaultScroll();
        this.$store.dispatch('setCapabilityFilter', filter);
      },
      locationFilterChange: function locationFilterChange(value) {
        if (value == "") {
          this.clearFilter('location');
          return;
        }
        var filter = JSON.parse(value);
        //utilities.defaultScroll();
        this.$store.dispatch('setLocationFilter', filter);
      },
      regionFilterChange: function regionFilterChange(value) {
        if (value == "") {
          this.clearFilter('region');
          return;
        }
        var filter = JSON.parse(value);
        //utilities.defaultScroll();
        this.$store.dispatch('setRegionFilter', filter);
      },
      loadMore: function loadMore() {
        this.$store.dispatch('updateScroll', utilities.getLoadMoreLocation());
        this.$store.dispatch('loadMore').then(function (firstResultIndex) {
          $('#experience-landing').find('.result p').get(firstResultIndex).focus();
        });
      },
      searchButtonClick: function searchButtonClick() {
        if (this.currentSearchKeyword !== "") {
          //utilities.defaultScroll()
          this.$store.dispatch('performKeywordSearch', this.currentSearchKeyword);
        }
        this.currentSearchKeyword = "";
      },
      clearFilter: function clearFilter(filterName) {
        this.$store.dispatch('clearFilter', filterName);
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },
      clearAllFilters: function clearAllFilters() {
        this.$store.dispatch('clearAllFilters');
        $('#search-landing-filters').focus();
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },
      onPopState: function onPopState() {
        if (window.history.state) {
          this.$store.dispatch('setState', window.history.state);
          if (this.scrollPosition !== 0) {
            utilities.scrollTo(this.scrollPosition);
          }
        } else {
          this.$store.dispatch('clearFilter');
        }
      },
      focusFilter: function focusFilter(filterName) {
        if (!this.focusedDropdowns.indexOf(filterName) > -1) {
          this.focusedDropdowns.push(filterName);
        }
      },
      blurFilter: function blurFilter(filterName) {
        if (this.focusedDropdowns.indexOf(filterName) > -1) {
          var index = this.focusedDropdowns.indexOf(filterName);
          if (index > -1) {
            this.focusedDropdowns.splice(index, 1);
          }
        }
      }
    }
  });
}

},{}],19:[function(require,module,exports){
'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

if (document.getElementById('professionals-landing')) {
  var utilities = {
    defaultElement: '#search-landing-filters',
    loadMoreElement: '#load-more-button',

    clearDropdownSelection: function clearDropdownSelection(id) {
      formattedId = '#' + id;
      $(formattedId).val('');
    },

    createGetter: function createGetter(propertyName) {
      return function (state) {
        return state[propertyName];
      };
    },

    defaultScroll: function defaultScroll() {
      var offset = parseInt($(window).height() / 2);
      var location = parseInt($(this.defaultElement).offset().top);
      $("html, body").animate({
        scrollTop: location - offset
      }, "slow", function () {
        var $resultCount = $('#search-landing-filters');
        $resultCount.focus();
      });

      return location - offset;
    },

    scrollTo: function scrollTo(location) {
      $("html, body").animate({
        scrollTop: location
      }, "slow", function () {
        var $resultCount = $('#search-landing-filters');
        $resultCount.focus();
      });
    },

    getLoadMoreLocation: function getLoadMoreLocation() {
      return parseInt($(this.loadMoreElement).offset().top);
    },

    getParams: function getParams(url) {

      var searchParams = url;

      if (searchParams.length == 0) return [];

      searchParams = searchParams.substr(1);

      var params = searchParams.split('&');

      var objects = params.map(function (equation) {

        var sides = equation.split('=');

        return {
          key: sides[0],
          value: sides[1]
        };
      });

      return objects;
    },

    getNewUrlParams: function getNewUrlParams(paramsList) {

      var paramPairs = paramsList.map(function (param) {

        return param.key + '=' + param.value;
      });

      var newUrlParams = '?' + paramPairs.join('&');

      return window.location.pathname + newUrlParams;
    },

    setUrlParameter: function setUrlParameter(key, value, url) {
      var params = this.getParams(url);

      var paramsWithKey = params.filter(function (param) {
        return param.key === key;
      });

      var paramsWithoutKey = params.filter(function (param) {
        return param.key !== key;
      });

      var paramWithKey = paramsWithKey[0];

      var paramExists = paramWithKey !== undefined;

      if (paramExists) {

        // Update value for parameter
        paramWithKey.value = value;
      } else {

        paramWithKey = {
          key: key,
          value: value
        };
      }

      var newParams = paramsWithoutKey;

      // Won't add key if value is null/undefined
      if (!!value) newParams.push(paramWithKey);

      return this.getNewUrlParams(newParams);
    },

    search: function search(letter, location, capability, topic, admission, diversity, globalSolution, position, name, keyword, take, skip) {
      return Vue.http.post('/api/professionals/search', {
        Letter: letter,
        Name: name,
        Keyword: keyword,
        Take: take,
        Skip: skip,
        Location: location,
        Capability: capability,
        Topic: topic,
        Admission: admission,
        Diversity: diversity,
        GlobalSolution: globalSolution,
        Position: position
      }).then(function (response) {
        if (response.status == 200) {
          return response.body;
        } else {
          console.log("error while retrieving search results");
          return defaultResults;
        }
      }, function (error) {
        return defaultResults;
      });
    },

    emptyValues: {
      letter: "",
      page: 0,
      take: serverJson.Take || 25,
      resultCount: 0,
      searching: false,
      selectedCapabilityFilter: { Name: "Capability", FilterId: "" },
      selectedTopicFilter: { Name: "Topic", FilterId: "" },
      selectedLocationFilter: { Name: "Location", FilterId: "" },
      selectedPositionFilter: { Name: "Position", FilterId: "" },
      selectedAdmissionFilter: { Name: "Admission", FilterId: "" },
      selectedDiversityFilter: { Name: "Diverse Attorney Directory", FilterId: "" },
      selectedGlobalSolutionFilter: { Name: "GlobalSolution", FilterId: "" },
      searchName: "",
      searchKeyword: "",
      filters: [],
      scrollLocation: 0,
      professionals: []
    },

    defaultValues: {
      letter: serverJson.SelectedLetter || "",
      page: serverJson.Page || 0,
      take: serverJson.Take || 25,
      professionals: serverJson.SearchResults.Results || [],
      resultCount: serverJson.SearchResults.ResultCount || 0,
      searching: serverJson.Searching || false,
      selectedCapabilityFilter: serverJson.CapabilityFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "Capability",
        FilterId: ""
      },
      selectedTopicFilter: serverJson.TopicFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "Topic",
        FilterId: ""
      },
      selectedLocationFilter: serverJson.LocationFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "Location",
        FilterId: ""
      },
      selectedPositionFilter: serverJson.PositionFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "Position",
        FilterId: ""
      },
      selectedAdmissionFilter: serverJson.BarAdmissionStateFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "Admission",
        FilterId: ""
      },
      selectedDiversityFilter: serverJson.DiverseAttorneyDirectoryOptInFilter || {
        Name: "Diverse Attorney Directory",
        FilterId: ""
      },
      selectedGlobalSolutionFilter: serverJson.GlobalSolutionFilters.find(function (item) {
        return item.Selected;
      }) || {
        Name: "GlobalSolution",
        FilterId: ""
      },
      capabilityFilters: serverJson.CapabilityFilters || [],
      topicFilters: serverJson.TopicFilters || [],
      locationFilters: serverJson.LocationFilters || [],
      positionFilters: serverJson.PositionFilters || [],
      admissionFilters: serverJson.BarAdmissionStateFilters || [],
      globalSolutionFilters: serverJson.GlobalSolutionFilters || [],
      searchName: serverJson.SearchName || "",
      searchKeyword: serverJson.SearchKeyword || "",
      filters: [],
      scrollLocation: serverJson.Scroll || 0,
      emailForm: {}
    }
  };

  Vue.use(VueResource);

  var store = new Vuex.Store({

    state: {
      professionals: utilities.defaultValues.professionals,
      letter: utilities.defaultValues.letter,
      page: utilities.defaultValues.page,
      take: utilities.defaultValues.take,
      resultCount: utilities.defaultValues.resultCount,
      searching: utilities.defaultValues.searching,
      selectedCapabilityFilter: utilities.defaultValues.selectedCapabilityFilter,
      selectedTopicFilter: utilities.defaultValues.selectedTopicFilter,
      selectedLocationFilter: utilities.defaultValues.selectedLocationFilter,
      selectedPositionFilter: utilities.defaultValues.selectedPositionFilter,
      selectedAdmissionFilter: utilities.defaultValues.selectedAdmissionFilter,
      selectedDiversityFilter: utilities.defaultValues.selectedDiversityFilter,
      selectedGlobalSolutionFilter: utilities.defaultValues.selectedGlobalSolutionFilter,
      capabilityFilters: utilities.defaultValues.capabilityFilters,
      topicFilters: utilities.defaultValues.topicFilters,
      locationFilters: utilities.defaultValues.locationFilters,
      positionFilters: utilities.defaultValues.positionFilters,
      admissionFilters: utilities.defaultValues.admissionFilters,
      globalSolutionFilters: utilities.defaultValues.globalSolutionFilters,
      searchName: utilities.defaultValues.searchName,
      searchKeyword: utilities.defaultValues.searchKeyword,
      filters: utilities.defaultValues.filters,
      scrollLocation: utilities.defaultValues.scrollLocation,
      // Email Form
      emailForm: utilities.defaultValues.emailForm,
      emailFormLoaded: false,
      formEmailAddress: '',
      showForm: false,
      formProfessionalId: ''
    },

    //For view / UI to render data from store.
    getters: _defineProperty({

      filtersSet: function filtersSet(state) {

        return state.searchKeyword !== "" || state.searchName !== "" || state.selectedAdmissionFilter.FilterId !== "" || state.selectedDiversityFilter.FilterId !== "" || state.selectedPositionFilter.FilterId !== "" || state.selectedLocationFilter.FilterId !== "" || state.selectedCapabilityFilter.FilterId !== "" || state.selectedTopicFilter.FilterId !== "" || state.selectedGlobalSolutionFilter.FilterId !== "" || state.letter !== "";
      },

      scrollLocation: utilities.createGetter('scrollLocation'),
      page: utilities.createGetter('page'),
      take: utilities.createGetter('take'),
      selectedSearchName: utilities.createGetter('searchName'),
      selectedSearchKeyword: utilities.createGetter('searchKeyword'),
      professionals: utilities.createGetter('professionals'),
      searching: utilities.createGetter('searching'),
      letter: utilities.createGetter('letter'),
      capabilityFilters: utilities.createGetter('capabilityFilters'),
      topicFilters: utilities.createGetter('topicFilters'),
      locationFilters: utilities.createGetter('locationFilters'),
      positionFilters: utilities.createGetter('positionFilters'),
      admissionFilters: utilities.createGetter('admissionFilters'),
      globalSolutionFilters: utilities.createGetter('globalSolutionFilters'),
      selectedCapabilityFilter: utilities.createGetter('selectedCapabilityFilter'),
      selectedTopicFilter: utilities.createGetter('selectedTopicFilter'),
      selectedLocationFilter: utilities.createGetter('selectedLocationFilter'),
      selectedPositionFilter: utilities.createGetter('selectedPositionFilter'),
      selectedAdmissionFilter: utilities.createGetter('selectedAdmissionFilter'),
      selectedDiversityFilter: utilities.createGetter('selectedDiversityFilter'),
      selectedGlobalSolutionFilter: utilities.createGetter('selectedGlobalSolutionFilter'),
      resultCount: utilities.createGetter('resultCount'),
      // Email Form
      emailForm: utilities.createGetter("emailForm"),
      emailFormLoaded: utilities.createGetter("emailFormLoaded"),
      formProfessionalId: utilities.createGetter('formProfessionalId'),
      showForm: utilities.createGetter('showForm')
    }, 'formProfessionalId', utilities.createGetter('formProfessionalId')),

    // For Actions to call and change / modify store.
    mutations: {
      updateScroll: function updateScroll(state, scrollLocation) {
        state.scrollLocation = scrollLocation;
      },

      loadMore: function loadMore(state, _loadMore) {
        state.page++;
      },

      updatePage: function updatePage(state, page) {
        state.page = page;
      },

      updateSearchName: function updateSearchName(state, text) {
        state.searchName = text;
      },

      updateSearchKeyword: function updateSearchKeyword(state, text) {
        state.searchKeyword = text;
      },

      updateLetter: function updateLetter(state, letter) {
        state.letter = letter;
      },

      updateSelectedCapabilityFilter: function updateSelectedCapabilityFilter(state, filter) {
        state.selectedCapabilityFilter = filter;
      },
      updateSelectedTopicFilter: function updateSelectedTopicFilter(state, filter) {
        state.selectedTopicFilter = filter;
      },

      updateSelectedLocationFilter: function updateSelectedLocationFilter(state, filter) {
        state.selectedLocationFilter = filter;
      },

      updateSelectedPositionFilter: function updateSelectedPositionFilter(state, filter) {
        state.selectedPositionFilter = filter;
      },

      updateSelectedAdmissionFilter: function updateSelectedAdmissionFilter(state, filter) {
        state.selectedAdmissionFilter = filter;
      },

      updateSelectedDiversityFilter: function updateSelectedDiversityFilter(state, filter) {
        state.selectedDiversityFilter = filter;
      },

      updateSelectedGlobalSolutionFilter: function updateSelectedGlobalSolutionFilter(state, filter) {
        state.selectedGlobalSolutionFilter = filter;
      },

      // Professionals

      updateProfessionals: function updateProfessionals(state, professionals) {
        state.professionals = professionals;
      },

      updateResultCount: function updateResultCount(state, count) {
        state.resultCount = count;
      },

      setSearching: function setSearching(state, searching) {
        state.searching = searching;
      },

      setState: function setState(state, newState) {
        for (var key in newState) {
          state[key] = newState[key];
        }
      },

      resetState: function resetState(state) {
        var defaults = utilities.emptyValues;
        for (var key in defaults) {
          state[key] = defaults[key];
        }
      },

      clearLetter: function clearLetter(state) {
        state.letter = utilities.emptyValues.letter;
        $('#search-landing-filters').focus();
      },

      clearFilter: function clearFilter(state, filterName) {
        if (filterName === "capability") {
          state.selectedCapabilityFilter = utilities.emptyValues.selectedCapabilityFilter;
          $('#search-landing-filters').focus();
          utilities.clearDropdownSelection('field-capability');
        } else if (filterName === "topic") {
          state.selectedTopicFilter = utilities.emptyValues.selectedTopicFilter;
          $('#search-landing-filters').focus();
          utilities.clearDropdownSelection('field-location');
        } else if (filterName === "location") {
          state.selectedLocationFilter = utilities.emptyValues.selectedLocationFilter;
          $('#search-landing-filters').focus();
          utilities.clearDropdownSelection('field-location');
        } else if (filterName === "position") {
          state.selectedPositionFilter = utilities.emptyValues.selectedPositionFilter;
          $('#search-landing-filters').focus();
          utilities.clearDropdownSelection('field-position');
        } else if (filterName === "admission") {
          $('#search-landing-filters').focus();
          state.selectedAdmissionFilter = utilities.emptyValues.selectedAdmissionFilter;
          utilities.clearDropdownSelection('field-admission');
        } else if (filterName === "diversity") {
          $('#search-landing-filters').focus();
          state.selectedDiversityFilter = utilities.emptyValues.selectedDiversityFilter;
        } else if (filterName === "globalSolution") {
          state.selectedGlobalSolutionFilter = utilities.emptyValues.selectedGlobalSolutionFilter;
          $('#search-landing-filters').focus();
          utilities.clearDropdownSelection('field-global-solution');
        } else if (filterName === "name") {
          $('#search-landing-filters').focus();
          state.searchName = utilities.emptyValues.searchName;
        } else if (filterName === "keyword") {
          $('#search-landing-filters').focus();
          state.searchKeyword = utilities.emptyValues.searchKeyword;
        }
      },

      // Email Form
      updateEmailForm: function updateEmailForm(state, emailForm) {
        state.emailForm = emailForm;
      },

      updateEmailFormLoaded: function updateEmailFormLoaded(state, emailFormLoaded) {
        state.emailFormLoaded = emailFormLoaded;
      },

      updateFormProfessionalId: function updateFormProfessionalId(state, formProfessionalId) {
        state.formProfessionalId = formProfessionalId;
      },

      updateShowForm: function updateShowForm(state, showForm) {
        state.showForm = showForm;
      }
    },

    // For View / UI to call to call an action or mutation against store.
    actions: {
      updateScroll: function updateScroll(context, scrollLocation) {
        context.commit("updateScroll", scrollLocation);
      },

      loadMore: function loadMore(context) {
        context.commit("loadMore");
        return context.dispatch("search", { loadMore: true });
      },

      setSearching: function setSearching(context, isSearching) {
        context.commit("setSearching", isSearching);
      },

      updateSearchName: function updateSearchName(context, text) {
        context.commit("updateSearchName", text);
      },

      updateSearchKeyword: function updateSearchKeyword(context, text) {
        context.commit("updateSearchKeyword", text);
      },

      updateLetter: function updateLetter(context, letter) {
        context.commit("updateLetter", letter);
        return context.dispatch('search');
      },
      updatePage: function updatePage(context, pageNumber) {
        context.commit("updatePage", pageNumber);
      },

      clearFilter: function clearFilter(context, filterName) {
        if (filterName == "letter") {
          context.commit("clearLetter");
        } else {
          context.commit("clearFilter", filterName);
        }
        return context.dispatch("search", { clearedFilterName: filterName });
      },

      setCapabilityFilter: function setCapabilityFilter(context, filter) {
        context.commit("updateSelectedCapabilityFilter", filter);
        return context.dispatch('search');
      },

      setTopicFilter: function setTopicFilter(context, filter) {
        context.commit("updateSelectedTopicFilter", filter);
        return context.dispatch('search');
      },

      setLocationFilter: function setLocationFilter(context, filter) {
        context.commit("updateSelectedLocationFilter", filter);
        return context.dispatch('search');
      },

      setPositionFilter: function setPositionFilter(context, filter) {
        context.commit("updateSelectedPositionFilter", filter);
        return context.dispatch('search');
      },

      setAdmissionFilter: function setAdmissionFilter(context, filter) {
        context.commit("updateSelectedAdmissionFilter", filter);
        return context.dispatch('search');
      },

      setDiversityFilter: function setDiversityFilter(context, filter) {
        context.commit("updateSelectedDiversityFilter", filter);
        return context.dispatch('search');
      },

      setGlobalSolutionFilter: function setGlobalSolutionFilter(context, filter) {
        context.commit("updateSelectedGlobalSolutionFilter", filter);
        return context.dispatch('search');
      },

      // Email Form
      updateEmailForm: function updateEmailForm(context, emailForm) {
        context.commit("updateEmailForm", emailForm);
      },

      updateEmailFormLoaded: function updateEmailFormLoaded(context, emailFormLoaded) {
        context.commit("updateEmailFormLoaded", emailFormLoaded);
      },

      updateFormProfessionalId: function updateFormProfessionalId(context, formProfessionalId) {
        context.commit("updateFormProfessionalId", formProfessionalId);
      },

      updateShowForm: function updateShowForm(context, showForm) {
        context.commit("updateShowForm", showForm);
      },

      search: function search(context, payload) {
        payload = payload || {};
        var clearedFilterName = payload.clearedFilterName;
        var loadMore = payload.loadMore;
        var skip = context.state.professionals.length;

        if (!loadMore) {
          // First page of people search starts at 0, instead of 1 like others
          context.commit('setState', { page: 0 });
          skip = 0;
        }

        // Execute search via web service, then push parameters to url
        return utilities.search(context.state.letter, context.state.selectedLocationFilter.FilterId, context.state.selectedCapabilityFilter.FilterId, context.state.selectedTopicFilter.FilterId, context.state.selectedAdmissionFilter.FilterId, context.state.selectedDiversityFilter.FilterId, context.state.selectedGlobalSolutionFilter.FilterId, context.state.selectedPositionFilter.FilterId, context.state.searchName, context.state.searchKeyword, context.state.take, skip).then(function (result) {

          // if we are not on first page, then add to results
          var firstResultIndex = 0;
          if (context.state.page != 0) {
            firstResultIndex = context.state.professionals.length;
          }

          if (loadMore) {
            var paginatedProfessionals = context.state.professionals;
            paginatedProfessionals = paginatedProfessionals.concat(result.Results);
            context.commit("updateProfessionals", paginatedProfessionals);
          } else {
            context.commit("updateProfessionals", result.Results);
          }

          context.commit("updateResultCount", result.ResultCount);
          var newUrl = "";

          if (context.state.page != 0) {
            newUrl = utilities.setUrlParameter("page", context.state.page, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.letter !== "" || clearedFilterName == "letter") {
            // Pass in the window location search if no url has been built yet
            // Otherwise, pass in the in progress new url, but only the query parameters
            newUrl = utilities.setUrlParameter("letter", context.state.letter, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedCapabilityFilter.FilterId !== "" || clearedFilterName === "capability") {
            newUrl = utilities.setUrlParameter("capability", context.state.selectedCapabilityFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedTopicFilter.FilterId !== "" || clearedFilterName === "topic") {
            newUrl = utilities.setUrlParameter("topic", context.state.selectedTopicFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedLocationFilter.FilterId !== "" || clearedFilterName === "location") {
            newUrl = utilities.setUrlParameter("location", context.state.selectedLocationFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedPositionFilter.FilterId !== "" || clearedFilterName === "position") {
            newUrl = utilities.setUrlParameter("position", context.state.selectedPositionFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedAdmissionFilter.FilterId !== "" || clearedFilterName === "admission") {
            newUrl = utilities.setUrlParameter("admission", context.state.selectedAdmissionFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedDiversityFilter.FilterId !== "" || clearedFilterName === "diversity") {
            newUrl = utilities.setUrlParameter("diversity", context.state.selectedDiversityFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.selectedGlobalSolutionFilter.FilterId !== "" || clearedFilterName === "globalSolution") {
            newUrl = utilities.setUrlParameter("globalSolution", context.state.selectedGlobalSolutionFilter.FilterId, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.searchName !== "" || clearedFilterName === "name") {
            newUrl = utilities.setUrlParameter("name", context.state.searchName, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.searchKeyword !== "" || clearedFilterName === "keyword") {
            newUrl = utilities.setUrlParameter("keyword", context.state.searchKeyword, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (context.state.scrollLocation !== 0) {
            newUrl = utilities.setUrlParameter("scroll", context.state.scrollLocation, newUrl !== "" ? '?' + newUrl.split('?')[1] : window.location.search);
          }

          if (newUrl !== "") {
            // If any filter is enabled, display search mode.
            // Will hide search if final filter is cleared.
            var enableSearch = context.getters.filtersSet;
            context.commit("setSearching", enableSearch);
            window.history.pushState(context.state, '', newUrl);
          }

          return firstResultIndex;
        });
      },

      performNameSearch: function performNameSearch(context, term) {
        if (term) {
          context.commit('updateSearchName', term);
        }
      },

      performKeywordSearch: function performKeywordSearch(context, term) {
        if (term) {
          context.commit('updateSearchKeyword', term);
        }
      },

      setState: function setState(context, newState) {
        context.commit('setState', newState);
      },

      resetState: function resetState(context) {
        context.commit('resetState');
      }

    }

  });

  var professionalLandingSearchApp = new Vue({

    el: '#professionals-landing',

    store: store,

    created: function created() {

      var app = this;
      Vue.http.get('/api/shared/emailform').then(function (response) {
        app.$store.dispatch('updateEmailForm', response.body.form);
        app.$store.dispatch('updateEmailFormLoaded', true);
        // app.loadRecaptcha();
      }, function (error) {
        console.log(error);
      });

      window.onpopstate = this.onPopState;

      if (this.$store.getters.scrollLocation !== 0) {
        utilities.scrollTo(this.$store.getters.scrollLocation);
      } else if (this.$store.getters.searching) {
        //utilities.defaultScroll()
      }
    },

    data: {
      currentSearchNameTerm: "",
      currentSearchKeywordTerm: "",
      showMobileFilters: false,
      capability: '',
      focusedDropdowns: [],
      form: null,
      name: "",
      email: "",
      message: "",
      recaptchaPublicKey: "",
      honeypot: "",
      CaptchaError: ""
    },

    // Computed called in UI for displaying values from store, call store getters
    computed: Vuex.mapGetters(['page', 'take', 'letter', 'professionals', 'searching', 'capabilityFilters', 'topicFilters', 'locationFilters', 'positionFilters', 'admissionFilters', 'globalSolutionFilters', 'selectedCapabilityFilter', 'selectedTopicFilter', 'selectedLocationFilter', 'selectedPositionFilter', 'selectedAdmissionFilter', 'selectedDiversityFilter', 'selectedGlobalSolutionFilter', 'selectedSearchName', 'selectedSearchKeyword', 'resultCount', 'scrollLocation', 'emailForm', 'emailFormLoaded', 'formEmailAddress', 'showForm', 'formProfessionalId']),

    // Called in UI - dispatch store actions
    methods: {

      toggleFilters: function toggleFilters() {
        this.showMobileFilters = !this.showMobileFilters;
      },

      loadMore: function loadMore() {
        this.$store.dispatch('updateScroll', utilities.getLoadMoreLocation());
        this.$store.dispatch("loadMore").then(function (firstResultIndex) {
          $('#professionals-landing').find('.professional-result-link').get(firstResultIndex).focus();
        });
      },

      // Executes button search
      searchButtonClick: function searchButtonClick() {

        if (this.currentSearchNameTerm !== '' || this.currentSearchKeywordTerm !== '') {
          this.$store.dispatch('performNameSearch', this.currentSearchNameTerm);
          this.$store.dispatch('performKeywordSearch', this.currentSearchKeywordTerm);
          this.$store.dispatch('search');

          // Clear all search text
          this.currentSearchNameTerm = "";
          this.currentSearchKeywordTerm = "";
        }
      },

      // Sets letter filter and executes search
      letterClick: function letterClick(letter) {
        $('#search-landing-filters').focus();
        this.$store.dispatch('updateLetter', letter);
        this.$store.dispatch('updatePage', 1);
      },

      clearFilter: function clearFilter(filterName) {
        this.$store.dispatch('clearFilter', filterName);
      },
      clearEmailForm: function clearEmailForm() {
        this.name = "";
        this.email = "";
        this.message = "";
        this.emailForm.NameError = "";
        this.emailForm.EmailError = "";
        this.emailForm.MessageError = "";
        this.CaptchaError = "";
      },

      hideForm: function hideForm() {
        var form = document.getElementsByClassName('email-form__wrapper')[0];
        if (!form.classList.contains('hide')) {
          form.classList.add('hide');
        }
      },

      unhideForm: function unhideForm() {
        var form = document.getElementsByClassName('email-form__wrapper')[0];
        if (form.classList.contains('hide')) {
          form.classList.remove('hide');
        }
      },

      clearAllFilters: function clearAllFilters() {
        this.$store.dispatch('resetState');
        utilities.clearDropdownSelection('field-capability');
        utilities.clearDropdownSelection('field-topic');
        utilities.clearDropdownSelection('field-location');
        utilities.clearDropdownSelection('field-position');
        utilities.clearDropdownSelection('field-admission');
        utilities.clearDropdownSelection('field-global-solution');
        $('#search-landing-filters').focus();
        window.history.pushState(utilities.defaultValues, '', window.location.pathname);
      },

      capabilityFilterChange: function capabilityFilterChange(value) {
        if (value == "") {
          this.clearFilter('capability');
          $('#search-landing-filters').focus();
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setCapabilityFilter', filter);
      },

      topicFilterChange: function topicFilterChange(value) {
        if (value == "") {
          this.clearFilter('topic');
          $('#search-landing-filters').focus();
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setTopicFilter', filter);
      },

      locationFilterChange: function locationFilterChange(value) {
        if (value == "") {
          this.clearFilter('location');
          $('#search-landing-filters').focus();
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setLocationFilter', filter);
      },

      positionFilterChange: function positionFilterChange(value) {
        if (value == "") {
          this.clearFilter('position');
          $('#search-landing-filters').focus();
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setPositionFilter', filter);
      },

      admissionFilterChange: function admissionFilterChange(value) {
        if (value == "") {
          this.clearFilter('admission');
          $('#search-landing-filters').focus();
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setAdmissionFilter', filter);
      },

      globalSolutionFilterChange: function globalSolutionFilterChange(value) {
        if (value == "") {
          this.clearFilter('globalSolution');
          $('#search-landing-filters').focus();
          console.log('focusss');
          return;
        }
        var filter = JSON.parse(value);
        this.$store.dispatch('setGlobalSolutionFilter', filter);
      },

      filterCount: function filterCount() {
        var setFilters = [];

        if (this.selectedCapabilityFilter.FilterId !== '') {
          setFilters.push(this.selectedCapabilityFilter);
        }
        if (this.selectedTopicFilter.FilterId !== '') {
          setFilters.push(this.selectedTopicFilter);
        }
        if (this.selectedLocationFilter.FilterId !== '') {
          setFilters.push(this.selectedLocationFilter);
        }
        if (this.selectedGlobalSolutionFilter.FilterId !== '') {
          setFilters.push(this.selectedGlobalSolutionFilter);
        }
        if (this.selectedPositionFilter.FilterId !== '') {
          setFilters.push(this.selectedPositionFilter);
        }
        if (this.selectedAdmissionFilter.FilterId !== '') {
          setFilters.push(this.selectedAdmissionFilter);
        }
        if (this.selectedDiversityFilter.FilterId !== '') {
          setFilters.push(this.selectedDiversityFilter);
        }
        if (this.selectedSearchName !== '') {
          setFilters.push(this.selectedSearchName);
        }
        if (this.selectedSearchKeyword !== '') {
          setFilters.push(this.selectedSearchKeyword);
        }
        if (this.letter !== '') {
          setFilters.push(this.letter);
        }

        return setFilters.length;
      },

      onPopState: function onPopState() {

        if (window.history.state) {
          this.$store.dispatch('setState', window.history.state);

          if (this.$store.getters.scrollLocation !== 0) {
            utilities.scrollTo(this.$store.getters.scrollLocation);
          }
        } else {
          this.$store.dispatch('resetState');
        }
      },

      accept: function accept() {
        var mailTo = 'mailto:' + this.disclaimerEmailAddress;
        window.location.href = mailTo;
        this.toggleModal();
      },

      toggleModal: function toggleModal(professionalId) {
        if (professionalId) {
          this.$store.dispatch('updateFormProfessionalId', professionalId);
        } else {
          this.$store.dispatch('updateFormProfessionalId', '');
        }

        var body = document.getElementsByTagName('body')[0];
        body.classList.toggle('modal-lock');
        this.$store.dispatch('updateShowForm', !this.showForm);
        if (!this.showModal) {
          this.clearEmailForm();
          this.unhideForm();
        }
        this.hideMessage();
      },
      validateInput: function validateInput() {
        var isValid = true;
        if (this.name == "") {
          this.emailForm.NameError = this.emailForm.NameText + " is required.";
          isValid = false;
        } else {
          this.emailForm.NameError = "";
        }
        if (this.email == "" || !this.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          this.emailForm.EmailError = "Please enter a valid " + this.emailForm.EmailText + ".";
          isValid = false;
        } else {
          this.emailForm.EmailError = "";
        }
        if (this.message == "") {
          this.emailForm.MessageError = this.emailForm.MessageText + " is required.";
          isValid = false;
        } else {
          this.emailForm.MessageError = "";
        }
        this.$forceUpdate();
        return isValid;
      },
      showMessage: function showMessage(message) {
        this.hideForm();
        this.emailForm.DisplayMessage = message;
        this.$forceUpdate();
      },
      hideMessage: function hideMessage(message) {
        this.emailForm.DisplayMessage = '';
      },

      loadRecaptcha: function loadRecaptcha() {
        var self = this;
        setTimeout(function () {
          if (typeof grecaptcha === 'undefined') {
            self.loadRecaptcha();
          } else {
            grecaptcha.render('form-captcha', {
              sitekey: '6Le6sx4UAAAAAEvHiBxQCMkeaKEoUdlu8nFn9c_q',
              callback: self.recaptchaCallback
            });
          }
        }, 100);
      },

      recaptchaCallback: function recaptchaCallback() {
        console.log("callback");
      },
      submit: function submit() {
        if (this.validateInput()) {
          var recaptchaPublicKey = document.getElementById('contact-us-form').getAttribute('data-recaptcha-key');
          var context = this;
          grecaptcha.execute(recaptchaPublicKey, { action: 'email_disclaimer' }).then(function (token) {
            var emailCount = sessionStorage.getItem('emailCount');
            if (emailCount) {
              sessionStorage.setItem('emailCount', parseInt(emailCount) + 1);
            } else {
              sessionStorage.setItem('emailCount', 1);
            }
            Vue.http.post('/api/shared/submitemailform', {
              ID: context.$store.getters.formProfessionalId,
              Name: context.name,
              Email: context.email,
              Message: context.message,
              EmailCount: emailCount,
              RecaptchaResponse: token
            }).then(function (response) {
              if (response.status == 200) {
                if (response.body.success) {
                  context.showMessage(response.body.message);
                } else if (response.body.recaptchaFailed) {
                  context.CaptchaError = "Error with Recaptcha.";
                } else {
                  console.log(response.body.message);
                }
              } else {
                console.log("error while submitting email");
                context.showMessage(response.body.message);
              }
            }, function (error) {
              context.showMessage("There was an error submitting the email, please try again later.");
            });
          }, function (response) {
            console.log("Error executing recaptcha");
          });
        }
      },

      toBoolean: function toBoolean(status) {
        if (status == "false") return false;
        if (status == "true") return true;
        return true;
      },

      focusFilter: function focusFilter(filterName) {
        if (!this.focusedDropdowns.indexOf(filterName) > -1) {
          this.focusedDropdowns.push(filterName);
        }
      },

      blurFilter: function blurFilter(filterName) {
        if (this.focusedDropdowns.indexOf(filterName) > -1) {
          var index = this.focusedDropdowns.indexOf(filterName);
          if (index > -1) {
            this.focusedDropdowns.splice(index, 1);
          }
        }
      }
    },

    components: {
      'email-form': {
        template: '#email-form-template',
        props: ['accept', 'toggleModal'],
        computed: {
          emailForm: function emailForm() {
            return this.$store.getters.emailForm;
          },

          emailFormLoaded: function emailFormLoaded() {
            return this.$store.getters.emailFormLoaded;
          },

          formEmailAddress: function formEmailAddress() {
            return this.$store.getters.formEmailAddress;
          },

          showForm: function showForm() {
            return this.$store.getters.showForm;
          }
        }
      }
    }
  });
}

},{}]},{},[2]);
